@import '../variables';

.members-map {
  .member-container {
    display: flex;
    flex-direction: column;
    height: 6rem;
    width: 40rem;
    border-bottom: 1px solid #EAEAEA;
    align-self: center;
    margin-left: 2rem;

    .member-row {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;

      .account-icon {
        position: relative;
        top: 0.5rem;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        margin-top: 1rem;
        text-align: center;
        background-color: #00AEEF;
        color: white;
      }

      .info-panel {
        display: relative;
        margin: 1.3rem auto 0 1rem;
        width: 20rem;

        .name-item {
          position: relative;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
        }

        .info-column {
          position: relative;
          color: black;
          line-height: 1.4;
        }

        .email {
          position: relative;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 1.1rem;
        }
      }

      .member-column-options {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        select {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1.1rem;
          line-height: 1.6rem;
          text-align: right;
          border: none;
          &:focus {border: none; outline: none;}
        }

        div {
          font-size: 1.2rem;
          font-weight: 600;
          color: black;
        }
      }
    }
  }
}

@import "../../variables.scss";

.download-projects {
  height: calc(100vh - 10.4rem);
  display: grid;
  align-items: center;

  .progress-wrapper {
    margin-top: 20px;
  }

  .progress-bar-custom {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    background: lightgrey;
  }
  .export-btns {
    align-self: center;
    position: relative;
    font-size: 1.2rem;
    line-height: 0.001rem;
    height: 2.8rem;
    background-color: #00aeef;
    border: 1px solid #00aeef;
    color: white !important;
    text-transform: none !important;
    font-weight: 400;
    font-style: normal;
    font-family: Open Sans;
    user-select: none;
    outline: none;
    cursor: pointer;
    padding: 1px 10px;
    transition: background-color 0.4s ease;
    &:hover {
      background-color: #0094c7;
    }
  }
}

@import '../../variables';

.authorization-page {
  font-weight: 700;
  text-transform: uppercase;

  .login-image {
    margin: 1rem 1rem 0;
    width: 40rem;
  }

  .page-title {
    text-align: center;
    font-size: 1.6rem;
    padding-top: 8rem;
    font-weight: 600;
  }

  .authorization-page-content {
    margin: auto;
    width: 55%;

    input, select {
      width: 100%;
      outline: none;
      border: 0;
      height: 4rem;
      padding-left: .5rem;
    }

    label {
      margin-bottom: .8rem;
    }

    .email-label {
      margin-top: 4.1rem;
    }

    .password-label {
      margin-top: 3rem;
    }

    .btn {
      width: 100%;
      padding: 1rem 0;
      font-size: 1.6rem;
      line-height: 1.6rem;
      text-transform: uppercase;
    }

    .login-button {
      margin: .9rem 0 1.3rem;
    }

    .send-email-button, .reset-button, .signup-button {
      margin: 3.8rem 0 1.3rem;
    }

    .google-button {
      margin-bottom: 1.3rem;
    }

    .forgot-password-link {
      margin-top: 2.3rem;
      text-align: right;
      font-size: .8rem;
    }

    .remember-me {
      margin-top: 2rem;
      color: $color-black;

      .checkbox-element {
        vertical-align: middle;
        height: 20px;

        .checkbox {
          margin: 3px 0 0;
        }

        & > span {
          height: 13px;
          width: 13px;
          top: 3px;
          left: 0;

          &:after {
            left: 3px;
            top: 0;
            width: 5px;
            height: 9px;
            border-width: 0 2px 2px 0;
          }
        }
      }

      .checkbox {
        height: auto;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        margin-right: .2rem;
      }

      .label {
        font-size: .8rem;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .account-text {
      margin: 3.7rem 0 1rem;
      text-align: center;
    }

    .signup-link, .back-to-login {
      text-align: center;

      a {
        color: $color-primary;
        font-weight: bold;
      }
    }

    .signup-link {
      a {
        color: $color-primary;
        font-weight: bold;
      }
    }
  }
}

@import '../../variables.scss';

.opportunity-section {
  background-color: $color-white;
  height: 35rem;
  // margin: 0px 15px;
  margin-right: 30px;

  &:hover {
    .applications-section {
      .delete-icon {
        visibility: visible;
      }
    }
  }

  .add-icon {
    position: absolute;
    transform: translateY(-50%);
    top: 4px;
    right: 1.5rem;
    cursor: pointer;
    width: 2.5rem;
    z-index: 100;
  }

  .applications-section {
    padding: 0 1.6rem 1.3rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 4px $color-secondary solid;
    border-left: 1px $color-secondary solid;
    border-right: 1px $color-secondary solid;
    position: relative;

    img {
      width: 2.5rem;
      margin-top: .5rem;
      vertical-align: bottom;
    }

    .delete-icon {
      visibility: hidden;
      position: absolute;
      top: -1.3rem;
      left: -1.3rem;
      cursor: pointer;
      transform: rotate(45deg);
    }

    input {
      border: 0;
      font-size: 1.2rem;
      line-height: 1.2;
      font-weight: 600;
      outline: none;
      width: 90%;
      height: 2rem;
    }
  }

  .customer-section {
    background-color: $color-white;
    padding: 0 1.6rem;
    height: 30rem;
    overflow: auto;

    img {
      width: 3.7rem;
      margin: 1rem 0 .5rem;
    }

    .segment-input {
      position: relative;

      .is-on-set {
        background-color: $color-opportunity-container !important;
      }

      input {
        outline: none;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        font-size: 1.1rem;
      }

      &:hover {
        .segment-menu {
          display: flex;
        }
      }

      &.customer-input {
        input {
          border-bottom: 4px $color-secondary solid;
          width: 75%;
          padding: 6px 5px;
          line-height: 1rem;
        }
      }

      &.set {
        input {
          background-color: $color-sub-segment-background;
          border-bottom: 0;
        }
      }

      &.sub {
        // text-align: right;

        input {
          background-color: $color-sub-segment-background;
          padding: 6px 5px;
          line-height: initial;
        }

        img {
          width: 1rem;
          cursor: pointer;
          vertical-align: middle;
          margin-left: .5rem;
          margin-bottom: 0;
        }

        .add-sub-segment-icon {
          width: 2.5rem;
          margin: 0;
          padding-left: 1rem;

          &:hover {
            ~.segment-menu {
              display: none;
            }
          }
        }

        .board-icon {
          width: 1.5rem;
        }

        // .segment-menu {
        //   right: -15rem;
        //   z-index: 1000;
        // }
      }

      .segment-menu {
        text-align: left;
        display: none;
        background-color: none!important;
        font-size: 1.1rem;
        line-height: 2rem;
        color: white;
        width: max-content;

        position: absolute;
        top: 0.6rem;
        right: 3.8rem;
        cursor: pointer;

        .send-section {
          margin-right: 4px;
        }

        img {
          width: 1rem;
          margin: 0 1rem 0 0;
        }

        span {
          vertical-align: middle;
        }
      }
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.print {
  padding: 60px;
  background-color: #fff;
}

.print .page-content {
  margin-left: 0;
}

.print .worksheet-page {
  padding: 60px;
  padding-left: 0;
}

.print .col-sm-3 {
  width: 25%;
}

.print .col-sm-4 {
  width: 33%;
}

.print .col-sm-6 {
  width: 50%;
}

.print .print-hide {
  display: none;
}

.print .opportunity-set-content {
  margin-left: 0;
}

.print .overview-section.set .section-image img  {
  margin-top: 3.2vh;
}

.print .worksheet-page-2 {
  margin-top: 100px;
}

.print .worksheet-page-3 {
  margin-top: 200px;
}

.readonly {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
  filter: grayscale(1);
}
@import '../../variables.scss';

.checkbox-element {
  display: inline-block;
  position: relative;
  height: 15px;
  width: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & > input:checked ~ span {
    background-color: $color-white;
  }

  & > input:checked ~ span:after {
    display: inline-block;
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid $color-black;
    transition: all 300ms ease;
    margin: 0;
    padding: 0;
  }

  & > span:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid $color-primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }

  .checkbox {
    height: 15px;
    width: 15px;
    margin: 3px 3px 3px 4px;
    padding: 0;
    background-color: $color-white;
    opacity: 1;
    color: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 0 solid #000000;
  }
}

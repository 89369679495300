@import '../../../variables';
  .agile-focus-content {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;

    .sticky-note {
      &__app, &__title {
        display: block;
      }

      &__app {
        margin-bottom: .5rem;
      }

      &__title {
        margin-top: 0 !important;
      }
    }

    .previous-screen-element {
        margin-left: -40vh; //10rem
        opacity: 0.25;
        width: 59.3vh;
        padding-top: 4px;
        margin-top: 4vh;
        @media screen and (min-height: 800px) {
          width: 65vh;
        }
    }
    // .first-screen-element {
    //     width: 55vh;
    //     // margin-left: -30vh;
    //     margin-left: -40vh;
    //     opacity: 0.25;
    //     @media screen and (min-height: 800px) {
    //       width: 60vh;
    //       margin-top: -1vh;
    //       margin-left: -45vh;
    //     }
    // }

    .agile-focus-element {
      margin-left: 10rem;
      width: 55vh;
      padding-top: 7px;
      margin-top: 6vh;
      position: relative;
      @media screen and (min-height: 800px) {
        margin-top: 6.2vh;
        width: 61vh;
      }
    }

    .box {
      --box-width: calc(10.4vh - 2px);
      --box-height: calc(10.1vh - 2px);
      --box-gap: 0.4vh;
      --base-left: -1px;
      --base-top: 0px;
      @media screen and (min-height: 800px) {
        --box-width: 11vh;
        --box-height: 10.7vh;
        --box-gap: 0.9vh;
        --base-left: 3px;
        --base-top: 3px;
      }
      width: var(--box-width);
      height: var(--box-height);
      background-color: $color-opportunity-container;
      position: absolute;
      top: var(--base-top);
      left: var(--base-left);
      cursor: pointer;
      border: 2px solid #fff;


      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0px 3px;
        max-height: 90%;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        line-height: 1rem;
        font-size: .9rem;
        // overflow: hidden;
        // overflow-y: auto;
        // overflow-wrap: break-word;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.highlighted {
        z-index: 1001 !important;
      }

      // &.place-in-storage {

      //   &.left {
      //     margin-left: 3px;
      //     margin-top: 3px;
      //     &.second {
      //       margin-top: 18%;
      //     }

      //     &.third {
      //       margin-top: 34%;
      //     }

      //     &.fourth {
      //       margin-top: 50%;
      //     }

      //     &.fifth {
      //       margin-top: 66%;
      //     }
      //   }

      //   &.bottom {
      //     margin-top: 82%;
      //     margin-left: 3px;

      //     &.second {
      //       margin-left: 20%;
      //     }

      //     &.third {
      //       margin-left: 42%;
      //     }

      //     &.fourth {
      //       margin-left: 63%;
      //     }
      //   }

      //   &.right {
      //     margin-top: 82%;
      //     margin-left: 82%;

      //     &.second {
      //       margin-top: 66%;
      //     }

      //     &.third {
      //       margin-top: 50%;
      //     }

      //     &.fourth {
      //       margin-top: 34%;
      //     }

      //     &.fifth {
      //       margin-top: 18%;
      //     }
      //   }

      //   &.top-right-1 {
      //     margin-left: 82%;
      //     margin-top: 3px;
      //     z-index: 5;
      //   }

      //   &.top-right-2 {
      //     margin-left: 82%;
      //     margin-top: 3px;
      //     top: 9px;
      //     right: 1px;
      //     left: auto;
      //     border-left: 1px solid white;//$color-light-grey;
      //     border-top: 1px solid white;//$color-light-grey;
      //     z-index: 6;
      //   }

      //   &.top-right-3 {
      //     margin-left: 82%;
      //     margin-top: 3px;
      //     top: 13px;
      //     right: -3px;
      //     left: auto;
      //     border-left: 1px solid white;//$color-light-grey;
      //     border-top: 1px solid white;//$color-light-grey;
      //     z-index: 7;
      //   }
      // }
      &.place-in-storage {

        &.right { // Switching sides cheat
          margin-left: -3px;
          margin-top: 83%;


          &.second {
            margin-top: 66%;
          }

          &.third {
            margin-top: 50%;
          }

          &.fourth {
            margin-top: 34%;
          }

          &.fifth {
            margin-top: 18%;
          }
        }

        &.bottom {
          margin-top: 83%;
          margin-left: 83%;

          &.second {
            margin-left: 63.6%;
          }

          &.third {
            margin-left: 41%;
          }

          &.fourth {
            margin-left: 18.6%;
          }
        }

        &.left { //cheating the sides
          margin-top: 3px;
          margin-left: 83%;


          &.fifth {
            margin-top: 66%;
          }

          &.fourth {
            margin-top: 50%;
          }

          &.third {
            margin-top: 34%;
          }

          &.second {
            margin-top: 18%;
          }
        }

        &.top-right-1 {
          margin-left: -3px;
          margin-top: 3px;
          z-index: 5;
        }

        &.top-right-2 {
          margin-left: -3px;
          margin-top: 3px;
          top: 6px;
          left: -1px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          margin-left: -3px;
          margin-top: 3px;
          top: 10px;
          left: -5px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.keep-open {
        margin-top: 16%;
        margin-left: 18.6%;

        &.left {
          &.second {
            margin-top: 32.6%;
          }

          &.third {
            margin-top: 48%;
          }
        }

        &.bottom {
          margin-top: 63.6%;

          &.second {
            margin-left: 41%;
          }
        }

        &.right {
          margin-top: 63.6%;
          margin-left: 63.6%;

          &.second {
            margin-top: 48%;
          }

          &.third {
            margin-top: 32.6%;
          }
        }

        &.top-right-1 {
          margin-top: 16%;
          margin-left: 63.6%;
          z-index: 5;
        }

        &.top-right-2 {
          top: 6px;
          left: 6px;
          margin-top: 16%;
          margin-left: 63.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          top: 10px;
          left: 10px;
          margin-top: 16%;
          margin-left: 63.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.pursue-now {
        margin-top: 43%;
        margin-left: 40%;
        z-index: 5;

        &.middle-2 {
          top: 6px;
          left: 6px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.middle-3 {
          top: 12px;
          left: 12px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }
    }
  }

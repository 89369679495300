@import '../../variables.scss';

.ability-section {
  padding: 0 1rem 2rem;
  margin: 0px 1rem;

  img {
    width: 1.9rem;
  }

  .bottom-border {
    border-bottom: 6px $color-secondary solid;
    width: 100%;
  }

  .add-icon {
    position: absolute;
    top: -1rem;
    right: -1rem;
    cursor: pointer;
  }

  &:hover {
    .delete-icon {
      visibility: visible;
    }
  }

  .delete-icon {
    visibility: hidden;
    position: absolute;
    top: -1rem;
    left: 0;
    cursor: pointer;
    transform: rotate(45deg);
  }

  .ability-title {
    outline: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    font-size: 1.2rem;
    line-height: 1rem;
    padding-left: 1rem;
    width: 90%;
  }

  input {
    border-bottom: 3px $color-secondary solid;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 100%;
    height: 2rem;
    line-height: 1rem;
  }
}

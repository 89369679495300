@import '../../variables.scss';

.edit-modal-container {
  position: fixed;
  z-index: 120;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  height: 29rem;
  background-color: white;
  flex-flow: column nowrap;
  user-select: none;

  .edit-btn-send {
    position:relative;
    top: 5rem;
    left: 2.6rem;
    height: 4rem;
    width: 12rem;
    background-color: #00AEEF!important;
    border: none!important;
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;

    &:focus {
      border: none;
      outline: none;
    }

    &.invited {
      opacity: 0.6;
    }
  }

  .edit-btn-cancel {
    position:relative;
    top: 5rem;
    left: 15.2rem;
    height: 4rem;
    width: 10rem;
    background-color: white!important;
    border: 1px solid black;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: black;

    &:focus {
      border: 1px solid black;
      outline: none;
    }
  }

  .warning-svg {
    position: relative;
    left: 2rem;
    top: 0.4rem;
  }

  .modal-name {
    font-size: 1.4rem;
    position: relative;
    left: 4.8rem;
    top: -2.3rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    color: black;
  }
  
  .cross-svg {
    position: relative;
    bottom: 5.5rem;
    left: 36rem;
    height: 3rem;
    width: 1.5rem;
    cursor: pointer;
  }

  .upper-line {
    position: relative;
    bottom: 5rem;
    border-bottom: 1px solid #EAEAEA;
  }

  .warning-title {
    position: relative;
    bottom: 5rem;
    left: 2rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: black;
  }

  .warning-intro {
    position: relative;
    bottom: 5.5rem;
    left: 2rem;
    font-family: Open Sans;
    font-style: normal;
    font-size: 1rem;
    color: black;
    width: 36rem;
    line-height: 1.6rem;
  }

  .warning-reload {
    position: relative;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    bottom: 0rem;
    top: 1rem;
    left: 2rem;
    z-index: 112;
  }

  .stable-construct {
    position: absolute;
    left: calc(50% - 4rem);
    top: calc(50% - 1rem);
    width: 8rem;
    height: 8rem;

    .timer {
      position: relative;
      width: 8rem;
      height: 8rem;
      z-index: 111;
      padding-top: 2.5rem;
      top: 4rem;
      left: 4rem;
      display: flex;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      font-weight: 600;
      font-size: 3.2rem;
      flex-flow: column nowrap;
      user-select: none;
      text-align: center;
      color: #00AEEF;
    }
  
    .timer-blue-bar {
      position: relative;
      width: 8rem;
      height: 8rem;
      z-index: 111;
      bottom: 16rem;
      left: 0rem;
      display: flex;
      font-weight: 600;
      flex-flow: column nowrap;
      user-select: none;
      text-align: center;
      animation: rotate 30s infinite;
      animation-timing-function:linear;
      -webkit-animation-fill-mode: forwards;

      @keyframes rotate {
        from {transform: rotate(0deg);}
        to {transform: rotate(-90deg);}
      }
    }
  
    .timer-circle {
      width: 8rem;
      height: 8rem;
      z-index:110;
      position:relative;
      left: 0rem;
      bottom: 8rem;
    }

    .timer-half-circle {
      width: 8rem;
      height: 8rem;
      z-index:112;
      position:relative;
      right: 2rem;
      bottom: 24rem;
    }
  }
}

// over 992px
@media (min-width: $screen-md-min) {
  .edit-modal-container {
    .time-background-container{
      left:2rem;
    }
  }
}

// over 1200px
@media (min-width: $screen-lg-min) {
  .edit-modal-container {

  }
}

// over 1440px
@media (min-width: $screen-xl-min) {
  .edit-modal-container {

  }
}

// over 1680px
@media (min-width: $screen-size-1680-min) {
  .edit-modal-container {

  }
}

.edit-only {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: none;
  z-index: 100;
  animation-name: darken;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-delay: infinite;

  @keyframes darken {
    from {
      background-color: none
    }
    to {
      background-color: rgba(0,0,0,0.7)
    }  
  }
}
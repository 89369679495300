.announcement-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: none;
  z-index: 100;
  animation-name: darken;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

.announcement-modal-dialog {
  position: fixed;
  z-index: 120;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 40rem;
  height: auto;
  background-color: white;
  padding: 2rem;

  .cross-svg {
    height: 3rem;
    width: 1.5rem;
    position: absolute;
    top: 3px;
    right: 12px;
    border: none;
    padding: 0;
    background: transparent;
  }

  .announcement-modal-body {
    margin-top: 10px;
  }

  .announcement-modal-title {
    font-weight: 700;
    color: #000000;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }

  .announcement-modal-desc {
    font-size: 1.5rem;
    padding-top: 1rem;

    & a {
      text-decoration: underline;
      color: #1baae3;
    }
  }
}

@import "../../variables.scss";

$bem: "comment-item";

.#{$bem} {
	position: relative;
	display: flex;
	padding: 12px 0;
	border-bottom: 1px solid #eaeaea;

	&:nth-last-child(1) {
		border-bottom: none;
		padding-bottom: 0;
	}

	&__delete, &__reply {
		cursor: pointer;
		position: absolute;
		width: 16px;
		height: 16px;
		right: 0;
		display: flex;
		transition: opacity 0.35s ease-in-out;
	}

	&__delete {
		top: 12px;
	}

	&__reply {
		top: 13px;

		&:hover {
			svg {
				fill: $color-primary;
			}
		}
	}

	&__left {
		margin-right: 12px;
		min-width: 3rem;
	}

	&__right {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__email {
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.4;
		color: black;
		margin-bottom: 8px;
	}

	&__content {
		font-family: Open Sans;
		font-size: 1.4rem;
		line-height: 1.4;
		color: black;
		white-space: pre-line;
	}

	&__more {
		cursor: pointer;
		color: $color-primary;
		font-size: 1.4rem;
		line-height: 1.4;
		font-weight: 600;
		display: flex;
		align-items: center;
		margin-left: -3px;
		margin-top: 8px;
	}

	&__arrow {
		width: 20px;
		height: 17px;
		display: flex;

		&[data-flipped="true"] {
			svg {
				transform: rotate(180deg);
			}
		}

		svg {
			fill: $color-primary;
		}
	}

	&__textarea {
		resize: none;
		display: block;
		width: 100%;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: #574f4f;
		outline: none;
		padding: 8px;
		border: 1px solid #CED1D7;
		text-align: left;
		height: 90px;
		margin-bottom: 8px;
	}

	&__send {
		background-color: #00AEEF;
		border: none;
		height: 2.7rem;
		width: 14.4rem;
		font-size: 1.2rem;
		line-height: 2.7rem;
		font-family: Open Sans;
		font-weight: 600;
		color: white;
		font-style: normal;
	}
}

.comment-list__list > .#{$bem} > .#{$bem}__delete {
	right: 25px;
}

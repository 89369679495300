@import '../../variables';

.overview-section {
  position: relative;

  .sticky-note {
    &__app, &__title {
      display: block;
    }

    &__app {
      margin-bottom: .5rem;
    }

    &__title {
      margin-top: 0 !important;
    }
  }

  > a {
    display: block;
  }

  &.set {
    float: right;
    margin-top: -52px;

    @media (min-width: $screen-xl-min) {
      margin-top: -54px;
    }

    @media (min-width: $screen-size-1680-min) {
      margin-top: -62px;
    }

    @media (min-width: $screen-size-1920-min) {
      margin-top: -72px;
    }

    > a {
      margin-bottom: 21px;
    }

    .section-image {
      position: relative;
    }

    .opportunity-set-controls {
      width: 75%;
      position: absolute;
      top: calc(50% + 5px);
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.2rem;
      text-align: center;

      &::-webkit-scrollbar {
        display: none;
      }

      &.empty {
        .no-opportunity {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .opportunity-wrapper {
        position: relative;
        width: 74%;
        padding-bottom: 74%;
        margin: 0 auto;
      }

      .plus-sign {
        color: $color-primary;
        display: flex;
        flex-basis: auto;
        flex-direction: column;
        width: 64px;
        height: 64px;
        padding: 0;
        font-size: 5rem;
        justify-content: center;
        align-self: center;
        place-self: center;

        &.empty {
          margin: auto;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .no-opportunity-text {
        color: $color-primary;

        &.link {
          text-decoration: underline;
        }
      }

      .no-opportunity-btn {
        font-size: 1.2rem;
      }

      .has-opportunity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-left: 0;
        display: grid;
        grid-template-columns: repeat(3, calc(33.33% - 5px));
        grid-template-rows: repeat(3, 1fr);
        gap: 30px;
        justify-content: center;
        align-items: center;

        .opportunity-container {
          position: relative;
          max-width: 100%;
          width: 100%;
          padding-bottom: 100%;
          place-self: center;
          margin: 0px;

          .opportunity-container-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          textarea {
            cursor: pointer;
            padding: 0px;
            margin-left: 5px;
            margin-right: 5px;
            color: $color-black;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  .section-image {

    img {
      margin: 0 0 3rem 0;
    }

    .arrows-image {
      width: 49vh;
      position: absolute;
      left: -1px;
      top: 14px;
    }
  }

  .section-heading {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .details {
    .section-icon {
      width: 2.2rem;
      display: inline-block;

      &.smaller {
        width: 3rem;
      }
    }

    .section-text {
      margin-left: .6rem;
      font-size: .9rem;
      line-height: 1.5rem;
      display: inline-block;
      vertical-align: middle;
      width: 86%;

      .link {
        text-decoration: underline;
        color: $color-primary;
      }
    }
  }

  // Attractiveness

  &.map {
    --box-x-offset: 15%;
    --box-y-offset: 25%;
    --box-size: 19%;
    --box-width: calc(var(--box-size) - 2px);
    --box-width-half: calc(1 * var(--box-width) / 2);
    --box-height: calc(var(--box-size) - 2px);
    --box-height-half: calc(1 * var(--box-height) / 2);
    --box-x-step: calc(74% / 6);
    --box-y-step: calc(66% / 6);

    width: 100%;
    margin-top: -17px;

    @media (min-width: $screen-xl-min) {
      margin-top: -18px;
    }

    @media (min-width: $screen-size-1680-min) {
      margin-top: -21px;
    }

    @media (min-width: $screen-size-1920-min) {
      margin-top: -24px;
    }

    .section-heading, .details {
      margin-left: 1.7rem;
    }

    .section-image {
      position: relative;
    }

    .attractiveness-map-element {
      position: absolute;
      top: 4%;
      left: 7%;
      width: 89%;
      height: 83%;
    }

    .box {
      width: var(--box-width);
      height: var(--box-height);
      left: 0;
      bottom: 0;
      background-color: $color-opportunity-container;
      position: absolute;
      z-index: 10;
      cursor: pointer;
      border: 2px solid #fff;
      transform: translate(calc(-1 * var(--box-width-half) + 2 * var(--box-x-offset)), calc(1 * var(--box-height-half) - 2 * var(--box-y-offset)));

      &.highlighted {
        z-index: 1001 !important;
      }

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-height: 90%;
        padding: 0px 3px;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        line-height: 1rem;
        font-size: .9rem;
        // overflow: hidden;
        // overflow-y: auto;
        // overflow-wrap: break-word;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .count {
        width: 1.3rem;
        height: 1.3rem;
        font-size: .9rem;
        line-height: 1.3rem;
        background-color: $color-primary;
        position: absolute;
        top: -5px;
        right: -10px;
        text-align: center;
        color: white;
        border-radius: 50px;
      }

      &.xlowmid {
        left: calc(var(--box-x-step) * 1);
      }

      &.xmid {
        left: calc(var(--box-x-step) * 2);
      }

      &.xmidhigh {
        left: calc(var(--box-x-step) * 3);
      }

      &.xhigh {
        left: calc(var(--box-x-step) * 4);
      }

      &.xhighsuper {
        left: calc(var(--box-x-step) * 5);
      }

      &.xsuper {
        left: calc(var(--box-x-step) * 6);
      }

      &.ylowmid {
        bottom: calc(var(--box-y-step) * 1);
      }

      &.ymid {
        bottom: calc(var(--box-y-step) * 2);
      }

      &.ymidhigh {
        bottom: calc(var(--box-y-step) * 3);
      }

      &.yhigh {
        bottom: calc(var(--box-y-step) * 4);
      }

      &.yhighsuper {
        bottom: calc(var(--box-y-step) * 5);
      }

      &.ysuper {
        bottom: calc(var(--box-y-step) * 6);
      }

      &.second {
        --box-x-offset: 18%;
        --box-y-offset: 22%;
        border-left: 1px solid white;
        border-top: 1px solid white;
        z-index: 100;
      }

      &.third {
        --box-x-offset: 21%;
        --box-y-offset: 19%;
        border-left: 1px solid white;
        border-top: 1px solid white;
        z-index: 1000;
      }
    }
  }


  // Agile

  &.agile {

    > a {
      margin-bottom: 21px;
    }

    .section-image {
      position: relative;
    }

    .agile-focus-element {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 27px);
    }

    .box {
      --box-size: 18%;
      --box-width: calc(var(--box-size) - 2px);
      --box-height: calc(var(--box-size) - 2px);
      --base-left: 0px;
      --base-top: 0px;

      width: var(--box-width);
      height: var(--box-height);
      background-color: $color-opportunity-container;
      position: absolute;
      top: var(--base-top);
      left: var(--base-left);
      cursor: pointer;
      border: 2px solid #fff;


      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0px 3px;
        max-height: 90%;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        line-height: 1rem;
        font-size: .9rem;
        // overflow: hidden;
        // overflow-y: auto;
        // overflow-wrap: break-word;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.highlighted {
        z-index: 1001 !important;
      }

      &.place-in-storage {

        &.right {
          margin-top: 81.5%;
          margin-left: 0.5%;

          &.second {
            margin-top: 65%;
          }

          &.third {
            margin-top: 49%;
          }

          &.fourth {
            margin-top: 33%;
          }

          &.fifth {
            margin-top: 17%;
          }
        }

        &.bottom {
          margin-top: 81.5%;
          margin-left: 82%;

          &.second {
            margin-left: 60.2%;
          }

          &.third {
            margin-left: 38.7%;
          }

          &.fourth {
            margin-left: 17%;
          }
        }

        &.left { //cheating the sides
          margin-top: 3px;
          margin-left: 82%;


          &.fifth {
            margin-top: 65%;
          }

          &.fourth {
            margin-top: 49%;
          }

          &.third {
            margin-top: 33%;
          }

          &.second {
            margin-top: 17%;
          }
        }

        &.top-right-1 {
          margin-left: -13px;
          margin-top: 3px;
          z-index: 5;
        }

        &.top-right-2 {
          margin-left: -13px;
          margin-top: 3px;
          top: 4px;
          left: -4px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          margin-left: -13px;
          margin-top: 3px;
          top: 8px;
          left: -8px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.keep-open {
        margin-top: 18.5%;
        margin-left: 18.5%;

        &.left {
          &.second {
            margin-top: 35.5%;
          }

          &.third {
            margin-top: 52.5%;
          }
        }

        &.bottom {
          margin-top: 63%;

          &.second {
            margin-left: 39%;
          }
        }

        &.right {
          margin-top: 63.5%;
          margin-left: 64%;

          &.second {
            margin-top: 65.5%;
          }

          &.third {
            margin-top: 67.5%;
          }
        }

        &.top-right-1 {
          margin-top: 14%;
          margin-left: 62.6%;
          z-index: 5;
        }

        &.top-right-2 {
          top: 9px;
          left: 9px;
          margin-top: 14%;
          margin-left: 62.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          top: 13px;
          left: 13px;
          margin-top: 14%;
          margin-left: 62.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.pursue-now {
        margin-top: 41%;
        margin-left: 41%;
        z-index: 5;

        &.middle-2 {
          top: 6px;
          left: 6px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.middle-3 {
          top: 12px;
          left: 12px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }
    }
  }
}

@import '../../variables';

.warning-modal {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $navigation-background-color;
  text-align: center;
  padding: 5rem 5rem 2.5rem;
  font-weight: 400;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);

  .close-icon {
    color: $color-white;
    font-size: 4rem;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    right: .5rem;
    cursor: pointer;
  }

  .modal-text {
    color: $color-white;
    text-transform: none;
    margin-bottom: 4rem;
  }

  .btn {
    padding: .5rem 3rem;
    width: 15rem;

    &.cancel-button {
      margin-right: 4rem;

      &:hover {
        color: $color-white;
      }
    }

    &.yes-button {
      color: $color-white;
      background-color: transparent;

      &:hover {
        background-color: $color-primary;
      }
    }

    &.ok-button {
      &:hover {
        color: $color-white;
      }
    }
  }
}

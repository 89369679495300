@import '../../variables';



.navigation-section-container {
  height: 5.2rem;
  width: 100%;
  text-align: center;
  background-color: white;
  font-weight: 600;
}

.navigation-section {
  height: 5.2rem;
  width: 100%;
  text-align: center;
  background-color: white;
  font-weight: 600;
  line-height: 3.2rem;
  position: relative;

  .navigation-section-main {
    width: 100%;
    height: 50%;
    text-align: left;
    font-weight: 600;

    .navigation-image {
      height: 3rem;

      img {
        width: 2rem;
      }
    }
  }

  a {
    &:link,
    &:visited {
      color: black;
      font-size: 2rem;
    }
  }

  .worksheet-title, .navigation-heading {
    &:hover {
      color: rgb(47, 47, 47);
    }
  }

  .worksheet-title {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2px;
  }

  .navigation-heading {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.2rem;
    width: 100%;
  }

  .navigation-subsection {
    font-size: 1.1rem;
    text-align: left;
    color:#00AEEF;
    font-weight: 600;
    line-height: 1.2rem;

    &.active, &:hover {
      text-decoration: underline;
      color: #1f5a9e;
    }
  }

  img {
    opacity: 0.4;
  }

  .gray-box {
    position: absolute;
    top: 0;
    left: -2px;
    opacity: 0;
    background-color: #EDEDED;
    width: calc(100% + 4px);
    height: 100%;
    z-index: 0;
  }

  &.active {
    background-color: #EDEDED;
    padding-left: 0;
    padding-right: 0;
    
    img {
      opacity: 1;
    }

    .gray-box {
      opacity: 1;
    }

    .navigation-subsection {
      background-color: #EDEDED;
      border-top: none;
      
      &:hover {
        background-color: #EDEDED;
      }
    }
  }
  
  &.bordered {
    padding-left: 0;
    padding-right: 0;
  }
}
.navigation-container {
  position: relative;
  width: 100%;
  padding-left: 2rem;

  .question-mark-icon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}
.navigation-item {
  text-align: left;
  padding: 1.5px 0;
}


.navigation-bar {
  white-space: nowrap;
}

.head-flex-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5.2rem;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
}
.head-flex-item-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start!important;
  align-self: center;
  width: 100%;
  height: 100%;
}

.head-flex-item-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end!important;
  line-height: 3.814rem;
  align-self: center;
  width: 50%;
  height: 3.814rem;
  margin-right: -15px;
}

.flex-justify-right {
  display: flex;
  flex-direction: column;
  align-items:stretch;
  justify-content:flex-end;
}

.flex-justify-right-items {
  display: flex;
}

.navigation-item-center {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 5.2rem;
  justify-content: space-between;
  padding-left: 4.2rem;
  & img {height: 2.4rem;}
}

.overview {
  & a {margin-top: 1.1rem;}
}

.chat-main-container {
  position: relative;

  /* TOOLTIP */
  .chat-tooltip {
    position: absolute;
    top: auto;
    width: 200px;
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
    -webkit-user-select: text;
    user-select: text;
    color: #ffffff;
    background: #313131;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;

    &::after {
      position: absolute;
      content: "";
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid #313131;
    }
  }

  .chat-message-input-tooltip {
    bottom: 72px;
    width: 240px;
    right: 50px;
  }

  .token-limit-tooltip {
    top: -30px;
    padding: 2px 4px;
    font-size: 10px;
    width: 100%;
    line-height: 20px;
  }

  /* TOGGLE CHAT BUTTON */
  .toggle-chat-btn {
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 20px;
    right: 20px;
    border: 0;
    padding: 0;
    color: #ffffff;
    background: none;
  }

  .toggle-chat-btn.active {
    cursor: pointer;
  }

  .toggle-chat-btn.disabled {
    cursor: not-allowed;

    & img {
      filter: grayscale(1);
    }

    &:hover .disabled-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .toggle-chat-btn.disabled > .disabled-tooltip {
    right: 28px;
    bottom: 76px;
    cursor: auto;

    & a {
      color: #1baae3;
      text-decoration: underline;
    }

    &::after {
      right: 5px;
      left: auto;
      transform: none;
    }
  }
}

.chat-container * {
  box-sizing: border-box;
}

.chat-container {
  width: 100%;
  max-width: 350px;
  height: 500px;
  position: fixed;
  bottom: 120px;
  z-index: 99;
  right: 0;

  .chat-token-limit {
    position: relative;
    cursor: pointer;

    &:hover .token-limit-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .chat-token-limit .progress {
    height: 6px;
    background-color: #d2eaed;
    border-radius: 0;
  }

  .chat-token-limit .progress-bar.blue {
    background-color: #00c5ff;
  }

  .chat-token-limit .progress-bar.orange {
    background-color: #fec109;
  }

  .chat-token-limit .progress-bar.red {
    background-color: #db3342;
  }

  .chat-container-body {
    border: 2px solid #e5e0e1;
    border-top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .chat-header {
    height: 62px;
    font-size: 16px;
    display: flex;
    gap: 5px;
    user-select: none;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 13px;
    background-color: #f8f8f8;
    box-shadow: 0 10px 15px -11px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    .chat-header-icon {
      width: 52px;
      height: 52px;
    }

    .chat-header-content {
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      color: #574f4f;
      flex: 1;
    }

    .btn-help-page {
      width: 2.6rem;
      margin-top: -6px;
      margin-right: -4px;
      align-self: flex-start;
    }
  }

  .chat-message-list {
    width: 100%;
    flex-grow: 1;
    margin: auto;
    padding: 0 13px;
    overflow-x: hidden;
    transition: background-color 300ms;
    background-color: rgb(231, 208, 172);

    /* Message */
    .chat-message {
      display: flex;
      max-width: 85%;
      margin: 22px 0;
      position: relative;
    }

    .chat-message.chat-message-outgoing {
      margin-left: auto;
      justify-content: flex-end;
    }

    .chat-message .chat-message-content {
      font-size: 13px;
      line-height: 1.7;
      padding: 9px 13px;
      word-break: break-word;
      overflow-wrap: anywhere;
      background-color: #c6e3fa;
      border-radius: 9px;
      box-shadow: 0 1px 0.5px rgba(11, 20, 6, 0.13);

      // MARKDOWN
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 600;
        line-height: 1.25;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      h3 {
        font-size: 1.25em;
      }

      h4 {
        font-size: 1em;
      }

      h5 {
        font-size: 0.875em;
      }

      h6 {
        font-size: 0.85em;
      }

      p {
        margin-top: 0;
        margin-bottom: 10px;
      }

      b,
      strong {
        font-weight: 600;
      }

      a {
        color: #1baae3;
        text-decoration: underline;
      }

      code {
        margin: 0;
        color: #1baae3;
        border-radius: 6px;
        padding: 0.2em 0.4em;
        white-space: break-spaces;
        background-color: #afb8c133;
      }

      code br {
        display: none;
      }

      del code {
        text-decoration: inherit;
      }

      h1 code,
      h2 code,
      h3 code,
      h4 code,
      h5 code,
      h6 code {
        padding: 0 0.2em;
        font-size: inherit;
      }

      ul,
      ol {
        margin: 0 0 10px;
        padding-left: 14px;
        white-space: normal;
      }

      ol ol,
      ul ol {
        list-style-type: lower-roman;
      }

      ul ul ol,
      ul ol ol,
      ol ul ol,
      ol ol ol {
        list-style-type: lower-alpha;
      }

      ul ul,
      ul ol,
      ol ol,
      ol ul {
        margin-top: 0;
        margin-bottom: 0;
      }

      li + li {
        margin-top: 0.25em;
      }

      blockquote {
        padding: 0 1em;
        margin: 0 0 10px;
        color: #636c76;
        border-left: 0.25em solid #d0d7de;
      }

      blockquote > :first-child {
        margin-top: 0;
      }

      blockquote > :last-child {
        margin-bottom: 0;
      }

      pre {
        margin-bottom: 10px;
      }

      pre > code {
        border: 0;
        padding: 0;
        background: transparent;
      }

      & > *:first-child {
        margin-top: 0 !important;
      }

      & > *:last-child {
        margin-bottom: 0 !important;
      }
    }

    .chat-message.chat-message-incoming .chat-message-content {
      color: #292929;
      background-color: #f7f7f7;
    }

    .chat-message.chat-message-outgoing .chat-message-content {
      color: #070d13;
      background-color: #60a5f8;
    }

    /* Chat tail */
    .chat-message-tail {
      bottom: 9px;
      position: absolute;
      display: inline-block;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

    .chat-message-tail.tail-in {
      left: -7px;
      border-right: 8px solid #f7f7f7;
    }

    .chat-message-tail.tail-out {
      right: -7px;
      border-left: 8px solid #60a5f8;
    }
  }

  // CHAT INPUT EDITOR
  .chat-message-input {
    display: flex;
    user-select: none;
    flex-wrap: nowrap;
    padding: 9px 13px;
    background-color: #f8f8f8;

    .chat-actions {
      display: flex;
      align-items: flex-end;
    }

    .chat-send-btn {
      border: 0;
      padding: 0;
      width: 25px;
      height: 25px;
      cursor: pointer;
      color: #6ea4f5;
      margin: 0 0 8px 7px;
      background: transparent;
    }

    .chat-send-btn.disabled {
      cursor: not-allowed;
    }

    .chat-download-btn {
      display: flex;
      align-self: end;
      width: 35px;
      height: 35px;
      margin-bottom: 3px;
    }

    .chat-message-input-editor {
      padding: 10px 7px;
      width: 100%;
      outline: none;
      resize: none;
      border-radius: 5px;
      line-height: 1.5;
      font-size: 13px;
      min-height: 1.47em;
      max-height: 95px;
      min-height: 61px;
      user-select: text;
      overflow-x: hidden;
      white-space: pre-wrap;
      word-break: break-word;
      background-color: #ffffff;
      border: 1px solid #9898a2;
      &:hover + .chat-message-input-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    .chat-message-input-editor.disabled {
      cursor: not-allowed;
      background-color: #e2e2e2;
    }

    .chat-message-input-editor::-webkit-scrollbar {
      width: 6px;
    }

    .chat-message-input-editor::-webkit-scrollbar-track {
      border-radius: 0;
    }

    .chat-message-input-editor::-webkit-scrollbar-thumb {
      margin: 2px;
      border-radius: 10px;
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  /* TYPING INDICATOR */
  .chat-message .typing {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 1px;
    box-sizing: border-box;
    background: #292929;
    border-radius: 50%;
  }

  .chat-message .typing.typing-1 {
    -webkit-animation: typing 3s infinite;
    animation: typing 3s infinite;
  }

  .chat-message .typing.typing-2 {
    -webkit-animation: typing 3s 250ms infinite;
    animation: typing 3s 250ms infinite;
  }

  .chat-message .typing.typing-3 {
    -webkit-animation: typing 3s 500ms infinite;
    animation: typing 3s 500ms infinite;
  }
}

@-webkit-keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 1px) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -1px) scale(1);
    opacity: 1;
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 1px) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -1px) scale(1);
    opacity: 1;
  }
}

.worksheet-page1 .abilities-section + .adder,
.worksheet-page1 .opportunities-section + .adder {
  z-index: 99;
}

@import '../variables.scss';

.select-dropdown {
  position: relative;
  display: inline-block;
  width: 80%;
  font-weight: 600;

  &.h-padding-left {
    padding-left: 1rem;
  }
  &.h-min-width {
    min-width: 400px;
    width: auto;
  }

  .dropdown-title {
    color: $color-primary;
    margin: 0 .4rem 0 .9rem;
    font-size: 1.6rem;
    line-height: 2rem;
    &.opportunity-select {
      display: block;
    }
  }

  .dropdown-title-black {
    margin: 0 .4rem 0 .9rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-black;
  }

  .dropdown-arrow {
    position: relative;
    width: 1rem;
    &.opportunity-select {
      left: 0.5rem;
    }
  }

  &:hover {
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 3rem;
    width: 17rem;
    background-color: white;
    position: absolute;
    font-size: 1.4rem;
    z-index: 1000;
    box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.2);
    max-height: 35rem;
    overflow: auto;


  }

  li a {
    color: $color-primary;
    text-decoration: none;
    transition: none;
    vertical-align: middle;
    font-size: 1rem;

    &.black {
      color: $color-black;
    }
  }

  li {
    padding: 4px 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  li:last-child {
    border-bottom: none;
  }

  li:hover {
    background-color: $color-primary;
    color: white;
    a {
      color: white;
    }
  }
}

.stay-there {
  position: absolute;
  width: 17rem!important;
  border: 1px solid black;
  height: 2.9rem!important;
}

.input-changed {
  width: 14rem!important;
}

.fix {
  //width: 17rem!important;
  top: 2.14rem;
  height: 2rem;
  position: relative;
}

.input-fixed-height {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1rem!important;
  left: 1rem;
}

.arrow-fix {
  width: 10rem!important;
  margin-bottom:0.3rem;
}

.header-arrow-style {
  position: absolute;
  height: 100%;
  right: 1.1rem;
  bottom: 0.2rem;
}

.dropdown-repair-worksheet3 {
  bottom: 1.2rem;
  min-width: 26rem;
  position: relative;
}

.section{
  padding-left: 10px;
  font-size: 10px;
}

@media (min-width: $screen-lg-min) {
  .fix {
    top: 1.2rem;
  }
  // .dropdown-repair-worksheet3 {
  //   bottom: 2rem;
  // }
}

@media screen and (max-width: 1400px) {
  .fix {
    top: 1.2rem;
  }
  // .dropdown-repair-worksheet3 {
  //   bottom: 2.1rem;
  // }
}

@media (min-width: $screen-xl-min) {
  .fix {
    top: 1.2rem;
  }
  // .dropdown-repair-worksheet3 {
  //   bottom: 1.8rem;
  // }
}

@media (min-width: $screen-size-1680-min) {
  .fix {
    top: 1.15rem;
  }
  // .dropdown-repair-worksheet3 {
  //   bottom: 1.5rem;
  // }
}

@media (min-width: $screen-size-1920-min) {
  .fix {
    top: 1.15rem;
  }
  // .dropdown-repair-worksheet3 {
  //   bottom: 1.1rem;
  // }
}


.notes-comment {
  .comment-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    position: relative;

    .column {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      &.text-field {
        height: 100%;
      }

      .notes-text-item {
        text-align: start;
        margin-top: .8rem;
        padding-left: 4.1rem;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        word-break: break-all;
        color: black;
        line-height: 1.9rem;
      }

      .comment-data {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .notes-name-item {
        line-height: 1.9rem;
        margin-left: 1.2rem;
        font-weight: 600;
        font-size: 1.4rem;
        min-height: 100%;
        color: black;
        margin-right: 2rem;
      }
    }
  }
}

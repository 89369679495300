@import '../../variables';

.worksheet-page {
  page-break-before: always;
  padding: 14px;


  .page-title {
    position: relative;
    img {
      width: 11rem;
      vertical-align: top;
    }
    .notes-btn {
      float: right;
      height: 24px;
      width: auto;
      cursor: pointer;
      margin-right: 2rem;
      margin-top: 4px;
    }

    .heading {
      display: inline-block;
      margin-left: 1rem;
      text-transform: uppercase;
      line-height: 2rem;
      padding-top: 0.5rem;

      .heading-small {
        font-size: 1rem;
        letter-spacing: 0.76px;
        font-weight: 700;
        line-height: 1.3rem;
      }

      .main-heading {
        font-weight: 600;
        font-size: 2rem;

        .question-mark-icon {
          width: 2.4rem;
          vertical-align: sub;
          cursor: pointer;
        }
      }
    }

    .info-text {
      color: $color-primary;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .bottom-line {
    margin-top: 2rem;
    font-size: 1.1rem;

    img {
      width: 2.5rem;
    }

    .bottom-text {
      display: inline-block;
      margin-left: 1rem;
      color: $color-primary;
      vertical-align: middle;
      font-weight: 600;
    }
  }
}

@import "../../variables";

$bem: "comment-origin";

.#{$bem} {
	cursor: pointer;
	display: flex;

	&__icon {
		position: relative;
		display: flex;
		flex-direction: column;
		align-self: center;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	&__dot {
		position: absolute;
		right: 0;
		top: 0;
		width: 6px;
		height: 6px;
		background-color: $color-primary;
		border-radius: 100%;
	}

	&__text {
		margin-left: 6px;
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.75rem;
	}
}
@import '../../variables.scss';

$section-border: 3px solid $color-background;
$inner-border: 1.4px solid $color-background;
$border-lg: 16px solid $color-background;
$radius: 15px;

.pdf-content {
  position: absolute;
  // z-index: -1;

  .overview-page {
    margin: 100px 24px 0;
    line-height: 30px;
  
    .heading {
      text-transform: uppercase;
      margin-top: 40pxm;
      font-size: 25px;
      font-weight: 600;
      margin-left: 30px;
    }
  
    .notes-btn {
      float: right;
      height: 24px;
      cursor: pointer;
      margin-right: 20px;
      margin-top: 4px;
    }
  
    > .overview-container {
      width: 100%;
      display: grid;
      grid-template-columns: 0.93fr 1fr 0.93fr;
      gap: 24px;

      .overview-section {
        position: relative;
      
        .sticky-note {
          &__app, &__title {
            display: block;
          }
      
          &__app {
            margin-bottom: 5px;
          }
      
          &__title {
            margin-top: 0 !important;
          }
        }
      
        > a {
          display: block;
        }
      
        &.set {
          float: right;
          margin-top: -52px;
      
          @media (min-width: $screen-xl-min) {
            margin-top: -54px;
          }
      
          @media (min-width: $screen-size-1680-min) {
            margin-top: -62px;
          }
      
          @media (min-width: $screen-size-1920-min) {
            margin-top: -72px;
          }
      
          > a {
            margin-bottom: 21px;
          }
      
          .section-image {
            position: relative;
          }
      
          .opportunity-set-controls {
            width: 75%;
            position: absolute;
            top: calc(50% + 5px);
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            text-align: center;
      
            &::-webkit-scrollbar {
              display: none;
            }
      
            &.empty {
              .no-opportunity {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }
      
            .opportunity-wrapper {
              position: relative;
              width: 74%;
              padding-bottom: 74%;
              margin: 0 auto;
            }
      
            .plus-sign {
              display: none;
            }
      
            .no-opportunity-text {
              color: $color-primary;
      
              &.link {
                text-decoration: underline;
              }
            }
      
            .no-opportunity-btn {
              font-size: 12px;
            }
      
            .has-opportunity {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              margin-left: 0;
              display: grid;
              grid-template-columns: repeat(3, calc(33.33% - 5px));
              grid-template-rows: repeat(3, 1fr);
              gap: 30px;
              justify-content: center;
              align-items: center;
      
              .opportunity-container {
                position: relative;
                max-width: 100%;
                width: 100%;
                padding-bottom: 100%;
                place-self: center;
                margin: 0px;
      
                .opportunity-container-wrapper {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;

                  .opportunity-app-title {
                    font-size: 9px;
                    line-height: 1;
                    margin-top: 5px;
                  }

                  .opportunity-title {
                    line-height: 10px;
                    font-size: 9px;
                    display: inline-block;
                    outline: none;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    background-color: $color-opportunity-container;
                    text-align: center;
                    resize: none;
                    margin-top: 5px;
                    margin-left: 8px;
                    margin-right: 8px;
                    padding: 0;
            
                    &:focus::placeholder {
                      color: transparent;
                    }
            
                    &:focus {
                      margin-top: 5px;
                    }
            
                    &.empty {
                      margin-top: 0;
                      @media screen and (max-height: 770px){
                        font-size: 8px;
                      }
                      @media screen and (max-height: 630px){
                        font-size: 7.5px;
                      }
            
                      &:focus {
                        margin-top: 5px;
                      }
                    }
                }
                }
      
                textarea {
                  cursor: pointer;
                  padding: 0px;
                  margin-left: 5px;
                  margin-right: 5px;
                  color: $color-black;
                }
      
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
        }
      
        .section-image {
      
          img {
            margin: 0 0 30px 0;
          }
      
          .arrows-image {
            width: 49vh;
            position: absolute;
            left: -1px;
            top: 14px;
          }
        }
      
        .section-heading {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          line-height: 30px;
        }
      
        .details {
          &.top {
            padding-bottom: 20px;
          }

          .section-icon {
            width: 22px;
            display: inline-block;
      
            &.smaller {
              width: 30px;
            }
          }
      
          .section-text {
            margin-left: 6px;
            font-size: 9px;
            line-height: 15px;
            display: inline-block;
            vertical-align: middle;
            width: 86%;
      
            .link {
              text-decoration: underline;
              color: $color-primary;
            }
          }
        }
      
        // Attractiveness
      
        &.map {
          --box-x-offset: 15%;
          --box-y-offset: 25%;
          --box-size: 19%;
          --box-width: calc(var(--box-size) - 2px);
          --box-width-half: calc(1 * var(--box-width) / 2);
          --box-height: calc(var(--box-size) - 2px);
          --box-height-half: calc(1 * var(--box-height) / 2);
          --box-x-step: calc(74% / 6);
          --box-y-step: calc(66% / 6);
      
          width: 100%;
          margin-top: -17px;
      
          @media (min-width: $screen-xl-min) {
            margin-top: -18px;
          }
      
          @media (min-width: $screen-size-1680-min) {
            margin-top: -21px;
          }
      
          @media (min-width: $screen-size-1920-min) {
            margin-top: -24px;
          }
      
          .section-heading, .details {
            margin-left: 17px;
          }
      
          .section-image {
            position: relative;
          }
      
          .attractiveness-map-element {
            position: absolute;
            top: 4%;
            left: 7%;
            width: 89%;
            height: 83%;
          }
      
          .box {
            width: var(--box-width);
            height: var(--box-height);
            left: 0;
            bottom: 0;
            background-color: $color-opportunity-container;
            position: absolute;
            z-index: 10;
            cursor: pointer;
            border: 2px solid #fff;
            transform: translate(calc(-1 * var(--box-width-half) + 2 * var(--box-x-offset)), calc(1 * var(--box-height-half) - 2 * var(--box-y-offset)));
      
            &.highlighted {
              z-index: 1001 !important;
            }
      
            .title {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              max-height: 90%;
              padding: 0px 3px;
              text-align: center;
              transform: translateX(-50%) translateY(-50%);
              line-height: 10px;
              font-size: 9px;
              // overflow: hidden;
              // overflow-y: auto;
              // overflow-wrap: break-word;
      
              &::-webkit-scrollbar {
                display: none;
              }
            }
      
            .count {
              width: 13px;
              height: 13px;
              font-size: 9px;
              line-height: 13px;
              background-color: $color-primary;
              position: absolute;
              top: -5px;
              right: -10px;
              text-align: center;
              color: white;
              border-radius: 50px;
            }
      
            &.xlowmid {
              left: calc(var(--box-x-step) * 1);
            }
      
            &.xmid {
              left: calc(var(--box-x-step) * 2);
            }
      
            &.xmidhigh {
              left: calc(var(--box-x-step) * 3);
            }
      
            &.xhigh {
              left: calc(var(--box-x-step) * 4);
            }
      
            &.xhighsuper {
              left: calc(var(--box-x-step) * 5);
            }
      
            &.xsuper {
              left: calc(var(--box-x-step) * 6);
            }
      
            &.ylowmid {
              bottom: calc(var(--box-y-step) * 1);
            }
      
            &.ymid {
              bottom: calc(var(--box-y-step) * 2);
            }
      
            &.ymidhigh {
              bottom: calc(var(--box-y-step) * 3);
            }
      
            &.yhigh {
              bottom: calc(var(--box-y-step) * 4);
            }
      
            &.yhighsuper {
              bottom: calc(var(--box-y-step) * 5);
            }
      
            &.ysuper {
              bottom: calc(var(--box-y-step) * 6);
            }
      
            &.second {
              --box-x-offset: 18%;
              --box-y-offset: 22%;
              border-left: 1px solid white;
              border-top: 1px solid white;
              z-index: 100;
            }
      
            &.third {
              --box-x-offset: 21%;
              --box-y-offset: 19%;
              border-left: 1px solid white;
              border-top: 1px solid white;
              z-index: 1000;
            }
          }
        }
      
      
        // Agile
      
        &.agile {
      
          > a {
            margin-bottom: 21px;
          }
      
          .section-image {
            position: relative;
          }
      
          .agile-focus-element {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 27px);
          }
      
          .box {
            --box-size: 18%;
            --box-width: calc(var(--box-size) - 2px);
            --box-height: calc(var(--box-size) - 2px);
            --base-left: 0px;
            --base-top: 0px;
      
            width: var(--box-width);
            height: var(--box-height);
            background-color: $color-opportunity-container;
            position: absolute;
            top: var(--base-top);
            left: var(--base-left);
            cursor: pointer;
            border: 2px solid #fff;
      
      
            .title {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              padding: 0px 3px;
              max-height: 90%;
              text-align: center;
              transform: translateX(-50%) translateY(-50%);
              line-height: 10px;
              font-size: 9px;
              // overflow: hidden;
              // overflow-y: auto;
              // overflow-wrap: break-word;
      
              &::-webkit-scrollbar {
                display: none;
              }
            }
            &.highlighted {
              z-index: 1001 !important;
            }
      
            &.place-in-storage {
      
              &.right {
                margin-top: 81.5%;
                margin-left: 0.5%;
      
                &.second {
                  margin-top: 65%;
                }
      
                &.third {
                  margin-top: 49%;
                }
      
                &.fourth {
                  margin-top: 33%;
                }
      
                &.fifth {
                  margin-top: 17%;
                }
              }
      
              &.bottom {
                margin-top: 81.5%;
                margin-left: 82%;
      
                &.second {
                  margin-left: 60.2%;
                }
      
                &.third {
                  margin-left: 38.7%;
                }
      
                &.fourth {
                  margin-left: 17%;
                }
              }
      
              &.left { //cheating the sides
                margin-top: 3px;
                margin-left: 82%;
      
      
                &.fifth {
                  margin-top: 65%;
                }
      
                &.fourth {
                  margin-top: 49%;
                }
      
                &.third {
                  margin-top: 33%;
                }
      
                &.second {
                  margin-top: 17%;
                }
              }
      
              &.top-right-1 {
                margin-left: -13px;
                margin-top: 3px;
                z-index: 5;
              }
      
              &.top-right-2 {
                margin-left: -13px;
                margin-top: 3px;
                top: 4px;
                left: -4px;
                right: auto;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 6;
              }
      
              &.top-right-3 {
                margin-left: -13px;
                margin-top: 3px;
                top: 8px;
                left: -8px;
                right: auto;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 7;
              }
            }
      
            &.keep-open {
              margin-top: 18.5%;
              margin-left: 18.5%;
      
              &.left {
                &.second {
                  margin-top: 35.5%;
                }
      
                &.third {
                  margin-top: 52.5%;
                }
              }
      
              &.bottom {
                margin-top: 63%;
      
                &.second {
                  margin-left: 39%;
                }
              }
      
              &.right {
                margin-top: 63.5%;
                margin-left: 64%;
      
                &.second {
                  margin-top: 65.5%;
                }
      
                &.third {
                  margin-top: 67.5%;
                }
              }
      
              &.top-right-1 {
                margin-top: 14%;
                margin-left: 62.6%;
                z-index: 5;
              }
      
              &.top-right-2 {
                top: 9px;
                left: 9px;
                margin-top: 14%;
                margin-left: 62.6%;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 6;
              }
      
              &.top-right-3 {
                top: 13px;
                left: 13px;
                margin-top: 14%;
                margin-left: 62.6%;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 7;
              }
            }
      
            &.pursue-now {
              margin-top: 41%;
              margin-left: 41%;
              z-index: 5;
      
              &.middle-2 {
                top: 6px;
                left: 6px;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 6;
              }
      
              &.middle-3 {
                top: 12px;
                left: 12px;
                border-left: 1px solid white;//$color-light-grey;
                border-top: 1px solid white;//$color-light-grey;
                z-index: 7;
              }
            }
          }
        }
      }
    }
  }

  .worksheet-page {
    page-break-before: always;
    padding: 14px;
  
  
    .page-title {
      position: relative;
      img {
        width: 110px;
        vertical-align: top;
      }
      .notes-btn {
        float: right;
        height: 24px;
        width: auto;
        cursor: pointer;
        margin-right: 20px;
        margin-top: 4px;
      }
  
      .heading {
        display: inline-block;
        margin-left: 10px;
        text-transform: uppercase;
        line-height: 20px;
        padding-top: 5px;
  
        .heading-small {
          font-size: 10px;
          letter-spacing: 0.76px;
          font-weight: 700;
          line-height: 13px;
        }
  
        .main-heading {
          font-weight: 600;
          font-size: 20px;
  
          .question-mark-icon {
            width: 24px;
            vertical-align: sub;
            cursor: pointer;
          }
        }
      }
  
      .info-text {
        color: $color-primary;
        font-size: 11px;
        font-weight: 600;
      }
    }
  
    .bottom-line {
      margin-top: 20px;
      font-size: 11px;
  
      img {
        width: 25px;
      }
  
      .bottom-text {
        display: inline-block;
        margin-left: 10px;
        color: $color-primary;
        vertical-align: middle;
        font-weight: 600;
      }
    }

    .worksheet-page1 {
      margin-top: 23px;
      position: relative;
      padding-left: 22px;
      line-height: 30px;
      font-size: 18px;
    
      .section-title {
        position: absolute;
        left: -14px;
        top: 120px;
        transform: rotate(-90deg);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
    
        &:nth-child(2) {
          top: 310px;
          left: -28px;
        }
    
        &:nth-child(3) {
          top: 500px;
          left: -22px;
        }
      }
    
      .worksheet-title {
        line-height: 10px;
    
        &.ability {
          padding-bottom: 0px;
        }
        &.identify {
          padding-top: 15px;
        }
    
        .question-mark-icon {
          width: 30px;
          display: inline-block;
          vertical-align: top;
          margin-right: 5px;
          cursor: pointer;
        }
    
        .title-content {
          display: inline-block;
    
          .main-heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 15px;
          }
    
          .sub-heading {
            font-size: 10px;
            line-height: 15px;
            padding-top: 7px;
          }
        }
      }
    
      .abilities-wrapper {
        &.scroll {
          overflow: auto;
    
          .abilities-section {
            margin-bottom: 0;
          }
    
          .col-sm-3 {
            background-color: $color-white;
            &.col-without-padding {
              padding: 0px;
            }
    
            &:nth-child(2){
              border-bottom-left-radius: 0;
            }
    
            &:nth-child(1){
              border-bottom-left-radius: 15px;
            }
          }
        }
      }
    
      .ability-row {
        flex-wrap: unset;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding-top: 10px;
    
        .col-sm-3 {
          background-color: $color-white;
          &.col-without-padding {
            padding: 0px;
          }
    
          &:nth-child(1){
            border-bottom-left-radius: 15px;
          }
    
          &:last-child {
            border-bottom-right-radius: 15px;
          }

          .ability-section {
            padding: 0 10px 20px;
            margin: 0px 10px;
          
            img {
              width: 19px;
            }
          
            .bottom-border {
              border-bottom: 6px $color-secondary solid;
              width: 100%;
            }
          
            .add-icon {
              position: absolute;
              top: -10px;
              right: -10px;
              cursor: pointer;
            }
          
            &:hover {
              .delete-icon {
                visibility: visible;
              }
            }
          
            .delete-icon {
              visibility: hidden;
              position: absolute;
              top: -10px;
              left: 0;
              cursor: pointer;
              transform: rotate(45deg);
            }
          
            .ability-title {
              outline: none;
              border-top: 0;
              border-left: 0;
              border-right: 0;
              border-bottom: 0;
              font-size: 12px;
              line-height: 10px;
              padding-left: 10px;
              width: 90%;
            }
          
            input {
              border-bottom: 3px $color-secondary solid;
              font-size: 10px;
              font-weight: 600;
              outline: none;
              border-top: 0;
              border-left: 0;
              border-right: 0;
              width: 100%;
              height: 23px;
              line-height: 18px;
            }
          }
        }
      }
    
      .counter {
        font-size: 12px;
        font-weight: 600;
        white-space: pre;
      }
    
      .add-icon {
        cursor: pointer;
      }
    
      .abilities-section {
        margin-bottom: 10px;
        position: relative;
    
        &:nth-last-child(1) {
          margin-right: 0;
        }
    
        > .row {
          overflow-x: auto;
        }
    
        & + .adder {
          position: absolute;
          top: 10px;
          right: 0;
          width: 25px;
          z-index: 100;
        }
      }
    
      .opportunity-equation {
        margin-top: 5px;
        font-size: 10px;
    
        img {
          width: 25px;
        }
    
        .part {
          margin: 0 3px;
    
          span {
            margin-left: 3px;
          }
        }
      }
    
      .arrow {
        border: solid rgb(237, 237, 237);
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 8px;
        background: white;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        left: 48.1%;
        bottom: -12px;
      }
      .opportunities-section__wrapper {
        position: relative;
      }
    
      .opportunities-section {
        overflow: auto;
        position: relative;
    
        > .row {
          overflow-x: auto;
        }
    
        & + .adder {
          position: absolute;
          top: 10px;
          right: 0;
          width: 25px;
          z-index: 100;
        }
    
        .row {
          flex-wrap: unset;
          width: 100%;
          padding-top: 13px;
          .col-sm-4 {
            max-width: 32%;
            &.col-without-padding {
              padding: 0px;
              max-width: 34.2%;
              flex: 0 0 34.2%;
              // &:last-of-type {
              //   .opportunity-section {
              //     margin-right: 0px;
              //   }
              // }
            }
          }
          &.row-without-margin {
            margin-left: 0px;
          }

          .opportunity-section {
            background-color: $color-white;
            height: 350px;
            // margin: 0px 15px;
            margin-right: 30px;
          
            &:hover {
              .applications-section {
                .delete-icon {
                  visibility: visible;
                }
              }
            }
          
            .add-icon {
              position: absolute;
              transform: translateY(-50%);
              top: 4px;
              right: 15px;
              cursor: pointer;
              width: 25px;
              z-index: 100;
            }
          
            .applications-section {
              padding: 0 16px 13px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border-bottom: 4px $color-secondary solid;
              border-left: 1px $color-secondary solid;
              border-right: 1px $color-secondary solid;
              position: relative;
          
              img {
                width: 25px;
                margin-top: 5px;
                vertical-align: bottom;
              }
          
              .delete-icon {
                visibility: hidden;
                position: absolute;
                top: -13px;
                left: -13px;
                cursor: pointer;
                transform: rotate(45deg);
              }
          
              input {
                border: 0;
                font-size: 12px;
                line-height: 1.2;
                font-weight: 600;
                outline: none;
                width: 90%;
                height: 20px;
              }
            }
          
            .customer-section {
              background-color: $color-white;
              padding: 0 16px;
              height: 300px;
              overflow: auto;
          
              img {
                width: 37px;
                margin: 10px 0 5px;
              }
          
              .segment-input {
                position: relative;
          
                .is-on-set {
                  background-color: $color-opportunity-container !important;
                }
          
                input {
                  outline: none;
                  border-top: 0;
                  border-left: 0;
                  border-right: 0;
                  border-bottom: 0;
                  font-size: 11px;
                }
          
                &:hover {
                  .segment-menu {
                    display: flex;
                  }
                }
          
                &.customer-input {
                  input {
                    border-bottom: 4px $color-secondary solid;
                    width: 75%;
                    padding: 3px 5px;
                    line-height: 26px;
                    height: 26px;
                  }
                }
          
                &.set {
                  input {
                    background-color: $color-sub-segment-background;
                    border-bottom: 0;
                  }
                }
          
                &.sub {
                  // text-align: right;
          
                  input {
                    background-color: $color-sub-segment-background;
                    padding: 3px 5px;
                    line-height: 18px;
                    height: 26px;
                  }
          
                  img {
                    width: 10px;
                    cursor: pointer;
                    vertical-align: middle;
                    margin-left: 5px;
                    margin-bottom: 0;
                  }
          
                  .add-sub-segment-icon {
                    width: 25px;
                    margin: 0;
                    padding-left: 10px;
          
                    &:hover {
                      ~.segment-menu {
                        display: none;
                      }
                    }
                  }
          
                  .board-icon {
                    width: 15px;
                  }
                }
          
                .segment-menu {
                  text-align: left;
                  display: none;
                  background-color: none!important;
                  font-size: 11px;
                  line-height: 20px;
                  color: white;
                  width: max-content;
          
                  position: absolute;
                  top: 6px;
                  right: 38px;
                  cursor: pointer;
          
                  .send-section {
                    margin-right: 4px;
                  }
          
                  img {
                    width: 10px;
                    margin: 0 10px 0 0;
                  }
          
                  span {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .view-page {
    margin: 94px 26px 0;
    font-size: 18px;
    line-height: 30px;
  
    .heading {
      background-color: $color-background;
  
      margin-top: 40px;
  
      .title {
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 15px;

        .notes-btn {
          float: right;
          height: 24px;
          cursor: pointer;
          margin-right: 20px;
          margin-top: 4px;
        }
      }

      .sheet-icon {
          width: 22px;
          align-self: center;
          display: inline-block;

          &.smaller {
            width: 30px;
          }
      }

      .description {
        text-transform: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
        max-width: 300px;
        margin-left: 15px;
        line-height: 15px;
        align-self: center;

        .link {
          text-decoration: underline;
        }
      }
    }

    .opportunity-set-content {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 200px;
      display: flex;
  
      *::-webkit-scrollbar { 
        display: none; 
      }
  
      .opportunity-set-element {
        position: relative;
        width: 570px;
  
        .opportunity-set-controls {
          width: 75%;
          position: absolute;
          top: calc(50% + 5px);
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          text-align: center;
    
          &::-webkit-scrollbar {
            display: none;
          }
    
          &.empty {
            .no-opportunity {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
    
          .opportunity-wrapper {
            position: relative;
            width: 74%;
            padding-bottom: 74%;
            margin: 0 auto;
          }
    
          .plus-sign {
           display: none;
          }
    
          .no-opportunity-text {
            color: $color-primary;
    
            &.link {
              text-decoration: underline;
            }
          }
    
          .no-opportunity-btn {
            font-size: 12px;
          }
    
          .has-opportunity {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-left: 0;
            display: grid;
            grid-template-columns: repeat(3, calc(33.33% - 5px));
            grid-template-rows: repeat(3, 1fr);
            gap: 30px;
            justify-content: center;
            align-items: center;
    
            .opportunity-container {
              position: relative;
              max-width: 100%;
              width: 100%;
              padding-bottom: 100%;
              place-self: center;
              margin: 0px;
    
              .opportunity-container-wrapper {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
            
                .sticky-note {
                  &__app, &__title {
                    display: block;
                  }
            
                  &__app {
                    margin-bottom: 5px;
                  }
            
                  &__title {
                    margin-top: 0 !important;
                  }
                }
            
                &::-webkit-scrollbar {
                  display: none;
                }
            
                .opportunity-app-title {
                  font-size: 9px;
                  line-height: 1;
                  margin-top: 5px;
                }
            
                .opportunity-title {
                    line-height: 10px;
                    font-size: 9px;
                    display: inline-block;
                    outline: none;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    background-color: $color-opportunity-container;
                    text-align: center;
                    resize: none;
                    margin-top: 5px;
                    margin-left: 8px;
                    margin-right: 8px;
                    padding: 0;
            
                    &:focus::placeholder {
                      color: transparent;
                    }
            
                    &:focus {
                      margin-top: 5px;
                    }
            
                    &.empty {
                      margin-top: 0;
                      @media screen and (max-height: 770px){
                        font-size: 8px;
                      }
                      @media screen and (max-height: 630px){
                        font-size: 7.5px;
                      }
            
                      &:focus {
                        margin-top: 5px;
                      }
                    }
                }
            
                .delete-opportunity-button{
                    display: none;
                    position: absolute;
                    flex-direction: column;
                    top: -1vh;
                    right: -1vh;
                    width: 18px;
                    justify-content: center;
                    cursor: pointer;
                    line-height: 15px;
                }
            
                &:hover .delete-opportunity-button{
                    display: block;
                }
              }
    
              textarea {
                cursor: pointer;
                padding: 0px;
                margin-left: 5px;
                margin-right: 5px;
                color: $color-black;
              }
    
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
  
      .plus-sign {
        display: none;
      }
  
      .next-screen-element {
        width: 59.3vh;
        padding-top: 4px;
        margin-top: 4vh;
        margin-left: 100px;
        opacity: 0.25;
        @media screen and (min-height: 800px) {
          width: 65vh;
        }
      }
      .third-screen-element {
        width: 55vh;
        padding-top: 7px;
        margin-top: 6vh;
        margin-left: 100px;
        opacity: 0.25;
        @media screen and (min-height: 800px) {
          margin-right: -6vh;
          margin-top: 6.2vh;
          width: 61vh;
        }
      }
    }
  
    .footer {
      margin-left: 20px;
  
      .box-icon {
        width: 30px;
        align-self: center;
      }
  
      .hint {
          font-size: 11px;
          margin-left: 15px;
          color: $color-primary;
      }
    }
  }

  .worksheet-page2 {
    font-size: 18px;
    line-height: 30px;

    .select-dropdown {
      position: relative;
      display: inline-block;
      width: 80%;
      font-weight: 600;
    
      &.h-padding-left {
        padding-left: 10px;
      }
      &.h-min-width {
        min-width: 400px;
        width: auto;
      }
    
      .dropdown-title {
        color: $color-primary;
        margin: 0 4px 0 9px;
        font-size: 16px;
        line-height: 20px;
        &.opportunity-select {
          display: block;
        }

        svg {
          display: none;
        }
      }
    
      .dropdown-title-black {
        margin: 0 4px 0 9px;
        font-size: 16px;
        line-height: 20px;
        color: $color-black;
      }
    
      .dropdown-arrow {
        position: relative;
        width: 10px;
        &.opportunity-select {
          left: 5px;
        }
      }
    }
  
    .question-mark-icon {
      width: 30px;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
      cursor: pointer;
    }
  
    .select-section {
      padding: 7px 0 7px 7px;
      background-color: $color-white;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 600;
  
      img {
        width: 32px;
        margin-right: 13px;
      }
    }
  
    .section-title {
      padding: 10px 0 0;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
    }
  
    .evaluate-section {
      background-color: $color-white;
      transition: opacity 0.35 ease-in-out;
  
      &[data-transparent="true"] {
        opacity: 0.5;
      }
  
      .h-padding-bottom {
        padding-bottom: 20px;
      }

      .attractiveness-section {
        margin: 25px 30px;
        border-bottom: 2px $color-secondary solid;
        height: 130px;
        position: relative;
      
        &:last-child {
          border-bottom: 0;
        }
      
        .section-icon {
          display: inline-block;
          vertical-align: top;
          margin-right: 22px;
      
          img {
            width: 33px;
          }
        }
      
        .section-evaluate {
          display: inline-block;
      
          .arrow-icon {
            width: 15px;
            vertical-align: middle;
            height: 26px;
            position: initial;
            transform: none;
            margin-left: 0px;
            z-index: -1;
          }
      
          .section-heading {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 12px;
            margin-top: 15px;
            padding-bottom: 5px;
          }
      
          .info-text {
            font-size: 9px;
            line-height: 12px;
            font-weight: 600;
            
          }
        }
      
        .section-comment {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  
    .overall {
      > .arrow-component .align-mid {
        width: calc((100% - 20px) / 4);
      }
    }
  
    .section-evaluate {
      > .arrow-component .align-mid {
        width: 24%;
      }
    }
  
    .arrow-component {
      position: relative;
      line-height: 13px;
      z-index: 90;
  
      .arrow-section {
        width: 35px;
        height: 17px;
        border-top: 1px solid $color-arrow-borders;
        border-bottom: 1px solid $color-arrow-borders;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
  
        &.filled {
          background-color: $color-primary;
        }
  
        &:nth-child(2n+1) {
          border-left: 1px solid $color-arrow-borders;
        }
  
        &:nth-child(8) {
          // border-right: 0px solid white;
        }
      }
  
      .levels-text {
        font-size: 7px;
        line-height: 7px;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;
        text-transform: uppercase;
        font-weight: 700;
        pointer-events: none;
  
        .align-mid {
          text-align: center;
          display: inline-block;
        }
  
        .align-right {
          text-align: right;
        }
      }
    }
  
    .overall-section {
      background-color: $color-white;
      padding-left: 20px;
      padding-right: 10px;
  
      > .row {
        margin: 0;
      }
  
      .arrow-icon {
        width: 20px;
        height: 34px;
        position: initial;
        transform: none;
        margin-left: 0px;
        z-index: -1;
      }
  
      .overall {
        width: 100%;
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
  
        .arrow-section {
          width: calc((100% - 20px) / 8);
          height: 21px;
        }
      }
  
      .col-sm-6 {
        display: flex;
      }
  
      .overall-image {
        margin-left: 20px;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        align-self: flex-end;
  
        img {
          width: 50px;
        }
      }
    }
  
    .section-border {
      background-color: $color-white;
      border-left: 14px $color-secondary solid;
      border-right: 6px $color-secondary solid;
      border-bottom: 6px $color-secondary solid;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 15px;
    }
  
    .section-border-right {
      background-color: $color-white;
      border-left: 6px $color-secondary solid;
      border-right: 14px $color-secondary solid;
      border-bottom: 6px $color-secondary solid;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 30px;
    }
  }

  .attractiveness-map-content {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    font-size: 18px;
    line-height: 30px;
  
    .sticky-note {
      &__app, &__title {
        display: block;
      }
  
      &__app {
        margin-bottom: 5px;
      }
  
      &__title {
        margin-top: 0 !important;
      }
    }
  
    .previous-screen-element {
      width: 55vh;
      margin-left: -39vh;
      opacity: 0.25;
      @media screen and (min-height: 800px) {
        width: 60vh;
        margin-top: -1vh;
        margin-left: -45vh;
      }
    }
  
  
    .next-screen-element {
      width: 55vh;
      padding-top: 7px;
      margin-top: 6vh;
      margin-left: 100px;
      opacity: 0.25;
      @media screen and (min-height: 800px) {
        margin-right: -6vh;
        margin-top: 6.2vh;
        width: 61vh;
      }
  
    }
  
    .attractiveness-map-element {
      position: relative;
      margin-left: 100px;
      width: 59.3vh;
      padding-top: 4px;
      margin-top: 4vh;
      @media screen and (min-height: 800px) {
        width: 65vh;
      }
    }
  
    .box {
  
      --box-width: calc(10.4vh - 2px);
      --box-height: calc(10.1vh - 2px);
      --box-gap: 1.2vh;
      --base-left: 6vh;
      --base-bottom: 8.2vh;
  
      @media screen and (min-height: 800px) {
        --box-width: calc(11vh - 2px);
        --box-height: calc(10.7vh - 2px);
        --box-gap: 2.1vh;
        --base-left: 6.5vh;
        --base-bottom: 8.8vh;
      }
  
      width: var(--box-width);
      height: var(--box-height);
      background-color: $color-opportunity-container;
      position: absolute;
      bottom: var(--base-bottom);
      left: var(--base-left);
      z-index: 10;
      cursor: pointer;
      border: 2px solid $color-white;
  
      &.highlighted {
        z-index: 50 !important;
      }
  
      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0px 3px;
        text-align: center;
        max-height: 90%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 10px;
        font-size: 9px;
        // overflow: hidden;
        // overflow-y: auto;
        // overflow-wrap: break-word;
  
        &::-webkit-scrollbar {
          display: none;
        }
      }
  
      .count {
        width: 13px;
        height: 13px;
        font-size: 9px;
        line-height: 13px;
        background-color: $color-primary;
        position: absolute;
        top: -5px;
        right: -10px;
        text-align: center;
        color: white;
        border-radius: 50px;
      }
  
      &.xlowmid {
        margin-left: calc( (var(--box-width) + var(--box-gap)) / 2);
      }
  
      &.xmid {
        margin-left: calc( var(--box-width) + var(--box-gap) );
      }
  
      &.xmidhigh {
        margin-left: calc( var(--box-width) + var(--box-gap) + (var(--box-width) + var(--box-gap)) / 2 );
      }
  
      &.xhigh {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + var(--box-gap) * 2);
        @media screen and (min-height: 800px) {
          margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + var(--box-gap));
        }
      }
  
      &.xhighsuper {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + (var(--box-width) + var(--box-gap)) / 2 + var(--box-gap) * 2);
        @media screen and (min-height: 800px) {
          margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + (var(--box-width) + var(--box-gap)) / 2 + var(--box-gap));
        }
      }
  
      &.xsuper {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 3 + var(--box-gap) * 2);
        @media screen and (min-height: 800px) {
          margin-left: calc( (var(--box-width) + var(--box-gap)) * 3 + var(--box-gap));
        }
      }
  
      &.ylowmid {
        margin-bottom: calc( (var(--box-height) + var(--box-gap)) / 2);
      }
  
      &.ymid {
        margin-bottom: calc( var(--box-height) + var(--box-gap) );
      }
  
      &.ymidhigh {
        margin-bottom: calc( var(--box-height) + var(--box-gap) + (var(--box-height) + var(--box-gap)) / 2 );
      }
  
      &.yhigh {
        margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 2 );
      }
  
      &.yhighsuper {
        margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 2 + (var(--box-height) + var(--box-gap)) / 2 );
      }
  
      &.ysuper {
        margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 3 );
      }
  
      &.second {
        bottom: calc(var(--base-bottom) - 6px);
        left: calc(var(--base-left) + 6px);
        border-left: 1px solid white;//$color-light-grey;
        border-top: 1px solid white;//$color-light-grey;
        z-index: 100;
      }
  
      &.third {
        bottom: calc(var(--base-bottom) - 12px);
        left: calc(var(--base-left) + 12px);
        border-left: 1px solid white;//$color-light-grey;
        border-top: 1px solid white;//$color-light-grey;
        z-index: 1000;
      }
    }
  }

  .worksheet-page3 {
    margin: 15px 0;
    padding-left: 15px;
    font-size: 18px;
    line-height: 30px;
  
    .select-section {
      transition: opacity 0.35 ease-in-out;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
  
      &[data-transparent="true"] {
        opacity: 0.5;
      }
    }
  
    .second-row {
      font-size: 14px;
    }
  
    .counter {
      font-size: 12px;
      font-weight: 600;
      white-space: pre;
    }
  
    .opportunities-section {
      max-width: calc(100% - 10px);
  
      & + .adder {
        cursor: pointer;
        position: absolute;
        top: 12.5px;
        right: 0px;
        width: 25px;
        z-index: 100;
      }
    }
  
    .question-mark-icon {
      position: absolute;
      left: -34px;
      top: -5px;
      width: 30px !important;
      margin-right: 5px;
      cursor: pointer;
    }
    .selection-container {
      padding: 0px 10px;
    }
  
    .section-text {
      font-size: 9px;
      line-height: 12px;
      font-weight: 600;
      .section-text-title {
        font-size: 11px;
        line-height: 20px;
      }
      .section-text-intro {
        position: relative;
        top: 7px;
      }
  
      .number {
        padding-right: 0;
        padding-left: 0px;
        font-size: 10px;
        line-height: 13px;
        align-self: flex-start;
        max-width: 3.3333%;
        &.with-line-height {
          line-height: 20px;
          margin-right: 5px;
        }
      }
  
      .text {
        padding-left: 2px;
        padding-right: 0;
        line-height: 14px;
  
        ul {
          padding-left: 15px;
          line-height: 14px;
        }
      }
  
      .section-heading {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
      }
    }
  
    .select-opportunity-section {
      position: relative;
      max-width: 33.4%;
  
      .add-icon {
        position: absolute;
        transform: translateY(0%);
        top: 4px;
        right: -10px;
        cursor: pointer;
        width: 25px;
        z-index: 100;
      }
  
      &:hover {
        .section-row {
          .delete-icon {
            visibility: visible;
          }
        }
      }

      .selection-boxes {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 10px;
        width: 100%;
      
        .box {
          background: $color-background;
          min-height: 35px;
          width: 73%;
          padding: 5px 11px;
          line-height: 11px;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;
      
          &:hover {
            cursor: pointer;
          }
      
          &:first-child {
            margin-left: auto;
          }
      
          &:nth-child(2) {
            margin: auto;
          }
        }
      
        .active {
          background: $color-primary;
          color: $color-white;
        }
      }

      .checkbox-wrapper {
        height: 35px;
        min-width: 200px;
        background: $color-background;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: stretch;
        margin: auto;
      
        .label {
          margin-left: 12px;
          font-size: 11px;
          font-weight: 600;
        }

        .checkbox-element {
          display: inline-block;
          position: relative;
          height: 15px;
          width: 20px;
          cursor: pointer;
          font-size: 14px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        
          & > input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        
          & > input:checked ~ span {
            background-color: $color-white;
          }
        
          & > input:checked ~ span:after {
            display: inline-block;
          }
        
          & > span {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid $color-black;
            transition: all 300ms ease;
            margin: 0;
            padding: 0;
          }
        
          & > span:after {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 1px;
            width: 7px;
            height: 14px;
            border: solid $color-primary;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(40deg);
            -ms-transform: rotate(40deg);
            transform: rotate(40deg);
          }
        
          .checkbox {
            height: 15px;
            width: 15px;
            margin: 3px 3px 3px 4px;
            padding: 0;
            background-color: $color-white;
            opacity: 1;
            color: #ffffff;
            border-radius: 2px 2px 2px 2px;
            border: 0 solid #000000;
          }
        }
      }
    }

    .select-dropdown {
      position: relative;
      display: inline-block;
      width: 80%;
      font-weight: 600;

      svg {
        display: none;
      }
    
      &.h-padding-left {
        padding-left: 10px;
      }
      &.h-min-width {
        min-width: 400px;
        width: auto;
      }
    
      .dropdown-title {
        color: $color-primary;
        margin: 0 4px 0 9px;
        font-size: 16px;
        line-height: 20px;
        &.opportunity-select {
          display: block;
        }
      }
    
      .dropdown-title-black {
        margin: 0 4px 0 9px;
        padding-right: 2px;
        font-size: 14px !important;
        line-height: 20px;
        color: $color-black;
      }
    
      .dropdown-arrow {
        position: relative;
        width: 10px;
        &.opportunity-select {
          left: 5px;
        }
      }
    }
  
    .section-row {
      min-height: 88px;
      &.bt-lg {
        min-height: calc(88px + 16px);
      }  
  
      .delete-icon {
        width: 25px;
        visibility: hidden;
        position: absolute;
        top: -13px;
        left: -3px;
        cursor: pointer;
        transform: rotate(45deg);
      }
    }
  
    .section-row-sm {
      min-height: 60px;
      padding-left: 29px;
  
      & > .selection-container {
        max-width: calc(75% - 60px);
      }
    }
  
    .white-bg {
      background: $color-white;
    }
  
    .inner {
      padding: 0 10px;
    }
  
    // borders
    .bt-lg {
      border-top: $border-lg;
    }
  
    .bb-md {
      border-bottom: $section-border;
    }
  
    .br-md {
      border-right: $section-border;
    }
  
    .bb-sm {
      border-bottom: $inner-border;
    }
  
    // border radius
  
    .radius-bl {
      border-bottom-left-radius: $radius;
    }
  
    .radius-br {
      border-bottom-right-radius: $radius;
    }
  
    //align
    .v-align {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    .h-align {
      display: flex;
      justify-content: center;
    }
  
    .arrow {
      border: solid rgb(237, 237, 237);
      border-width: 0 9px 9px 0;
      display: inline-block;
      padding: 8px;
      background: white;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      left: 45%;
      top: -21px;
    }
  
    .box-icon {
      width: 35px;
    }
  
    .opportunities-section {
      flex-wrap: unset;
      overflow: auto;
    }
  }

  .agile-focus-content {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;

    .sticky-note {
      &__app, &__title {
        display: block;
      }

      &__app {
        margin-bottom: 5px;
      }

      &__title {
        margin-top: 0 !important;
      }
    }

    .previous-screen-element {
        margin-left: -40vh;
        opacity: 0.25;
        width: 59.3vh;
        padding-top: 4px;
        margin-top: 4vh;
        @media screen and (min-height: 800px) {
          width: 65vh;
        }
    }

    .agile-focus-element {
      margin-left: 100px;
      width: 55vh;
      padding-top: 7px;
      margin-top: 6vh;
      position: relative;
      @media screen and (min-height: 800px) {
        margin-top: 6.2vh;
        width: 61vh;
      }
    }

    .box {
      --box-width: calc(10.4vh - 2px);
      --box-height: calc(10.1vh - 2px);
      --box-gap: 0.4vh;
      --base-left: -1px;
      --base-top: 0px;
      @media screen and (min-height: 800px) {
        --box-width: 11vh;
        --box-height: 10.7vh;
        --box-gap: 0.9vh;
        --base-left: 3px;
        --base-top: 3px;
      }
      width: var(--box-width);
      height: var(--box-height);
      background-color: $color-opportunity-container;
      position: absolute;
      top: var(--base-top);
      left: var(--base-left);
      cursor: pointer;
      border: 2px solid #fff;


      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0px 3px;
        max-height: 90%;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        line-height: 10px;
        font-size: 9px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.highlighted {
        z-index: 1001 !important;
      }

      &.place-in-storage {

        &.right {
          margin-left: -3px;
          margin-top: 83%;


          &.second {
            margin-top: 66%;
          }

          &.third {
            margin-top: 50%;
          }

          &.fourth {
            margin-top: 34%;
          }

          &.fifth {
            margin-top: 18%;
          }
        }

        &.bottom {
          margin-top: 83%;
          margin-left: 83%;

          &.second {
            margin-left: 63.6%;
          }

          &.third {
            margin-left: 41%;
          }

          &.fourth {
            margin-left: 18.6%;
          }
        }

        &.left { //cheating the sides
          margin-top: 3px;
          margin-left: 83%;


          &.fifth {
            margin-top: 66%;
          }

          &.fourth {
            margin-top: 50%;
          }

          &.third {
            margin-top: 34%;
          }

          &.second {
            margin-top: 18%;
          }
        }

        &.top-right-1 {
          margin-left: -3px;
          margin-top: 3px;
          z-index: 5;
        }

        &.top-right-2 {
          margin-left: -3px;
          margin-top: 3px;
          top: 6px;
          left: -1px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          margin-left: -3px;
          margin-top: 3px;
          top: 10px;
          left: -5px;
          right: auto;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.keep-open {
        margin-top: 16%;
        margin-left: 18.6%;

        &.left {
          &.second {
            margin-top: 32.6%;
          }

          &.third {
            margin-top: 48%;
          }
        }

        &.bottom {
          margin-top: 63.6%;

          &.second {
            margin-left: 41%;
          }
        }

        &.right {
          margin-top: 63.6%;
          margin-left: 63.6%;

          &.second {
            margin-top: 48%;
          }

          &.third {
            margin-top: 32.6%;
          }
        }

        &.top-right-1 {
          margin-top: 16%;
          margin-left: 63.6%;
          z-index: 5;
        }

        &.top-right-2 {
          top: 6px;
          left: 6px;
          margin-top: 16%;
          margin-left: 63.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.top-right-3 {
          top: 10px;
          left: 10px;
          margin-top: 16%;
          margin-left: 63.6%;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }

      &.pursue-now {
        margin-top: 43%;
        margin-left: 40%;
        z-index: 5;

        &.middle-2 {
          top: 6px;
          left: 6px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 6;
        }

        &.middle-3 {
          top: 12px;
          left: 12px;
          border-left: 1px solid white;//$color-light-grey;
          border-top: 1px solid white;//$color-light-grey;
          z-index: 7;
        }
      }
    }
  }
}
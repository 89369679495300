@import '../../../variables';

  .opportunity-set-content {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 20rem;
    display: flex;

    *::-webkit-scrollbar { 
      display: none; 
    }

    .opportunity-set-element {
      position: relative;
      width: 55vh;
      @media screen and (min-height: 800px) {
        width: 60vh;
      }

      .opportunity-set-controls {
        width: 75%;
        position: absolute;
        top: calc(50% + 5px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        text-align: center;
  
        &::-webkit-scrollbar {
          display: none;
        }
  
        &.empty {
          .no-opportunity {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
  
        .opportunity-wrapper {
          position: relative;
          width: 74%;
          padding-bottom: 74%;
          margin: 0 auto;
        }
  
        .plus-sign {
          color: $color-primary;
          display: flex;
          flex-basis: auto;
          flex-direction: column;
          width: 64px;
          height: 64px;
          padding: 0;
          font-size: 5rem;
          justify-content: center;
          align-self: center;
          place-self: center;
  
          &.empty {
            margin: auto;
          }
  
          &:hover {
            cursor: pointer;
          }
        }
  
        .no-opportunity-text {
          color: $color-primary;
  
          &.link {
            text-decoration: underline;
          }
        }
  
        .no-opportunity-btn {
          font-size: 1.2rem;
        }
  
        .has-opportunity {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin-left: 0;
          display: grid;
          grid-template-columns: repeat(3, calc(33.33% - 5px));
          grid-template-rows: repeat(3, 1fr);
          gap: 30px;
          justify-content: center;
          align-items: center;
  
          .opportunity-container {
            position: relative;
            max-width: 100%;
            width: 100%;
            padding-bottom: 100%;
            place-self: center;
            margin: 0px;
  
            .opportunity-container-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
  
            textarea {
              cursor: pointer;
              padding: 0px;
              margin-left: 5px;
              margin-right: 5px;
              color: $color-black;
            }
  
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }

    .plus-sign {
      color: $color-primary;
      display: flex;
      flex-basis: auto;
      flex-direction: column;
      width: 12vh;
      height: 10vh;
      margin: 0.5rem;
      padding: 0;
      justify-content: center;
      font-size: 5rem;

      &.empty {
        margin: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .next-screen-element {
      width: 59.3vh;
      padding-top: 4px;
      margin-top: 4vh;
      margin-left: 10rem;
      opacity: 0.25;
      @media screen and (min-height: 800px) {
        width: 65vh;
      }
    }
    .third-screen-element {
      width: 55vh;
      padding-top: 7px;
      margin-top: 6vh;
      margin-left: 10rem;
      opacity: 0.25;
      @media screen and (min-height: 800px) {
        margin-right: -6vh;
        margin-top: 6.2vh;
        width: 61vh;
      }
    }
}

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-xl: 1440px !default;
$screen-xl-min: $screen-xl !default;
$screen-size-1680: 1680px !default;
$screen-size-1680-min: $screen-size-1680 !default;
$screen-size-1920: 1920px !default;
$screen-size-1920-min: $screen-size-1920 !default;
$screen-size-2320: 2320px !default;
$screen-size-2320-min: $screen-size-2320 !default;
$screen-size-2630: 2630px !default;
$screen-size-2630-min: $screen-size-2630 !default;
$screen-size-3040: 3040px !default;
$screen-size-3040-min: $screen-size-3040 !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
// End of screen sizes

$header-height: 7.1rem;

$transition: 0.5s;

$color-transparent: transparent;
$color-white: #fff;
$color-black: #574f4f;
$color-light-grey: #d2d2d2;

$color-primary: #1baae3;
$color-secondary: #ededed;
$color-background: #ededed;

$color-turqoise: #4bc1b7;
$color-turqoise-dark: #37adbd;
$color-image-background: #d4edfd;
$color-opportunity-container: #f2ee93;
$color-sub-segment-background: #e6e6e6;
$color-segment-menu-background: #818285;
$color-arrow-borders: #b3b3b3;

$font-primary: 'Open Sans';

$navigation-background-color: #525050;
$navigation-background-color-active: #1baae3;

@import '../../variables.scss';

.worksheet-page1 {
  margin-top: 2.3rem;
  position: relative;
  padding-left: 2.2rem;

  .section-title {
    position: absolute;
    left: -1.4rem;
    top: 12rem;
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;

    &:nth-child(2) {
      top: 31rem;
      left: -2.8rem;
    }

    &:nth-child(3) {
      top: 50rem;
      left: -2.2rem;
    }
  }

  .worksheet-title {
    line-height: 1rem;

    &.ability {
      padding-bottom: 0rem;
    }
    &.identify {
      padding-top: 1.5rem;
    }

    .question-mark-icon {
      width: 3rem;
      display: inline-block;
      vertical-align: top;
      margin-right: .5rem;
      cursor: pointer;
    }

    .title-content {
      display: inline-block;

      .main-heading {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.5rem;
      }

      .sub-heading {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 0.7rem;
      }
    }
  }

  .abilities-wrapper {
    // padding-top: 1rem;
    &.scroll {
      overflow: auto;

      .abilities-section {
        margin-bottom: 0;
      }

      .col-sm-3 {
        background-color: $color-white;
        &.col-without-padding {
          padding: 0px;
        }

        &:nth-child(2){
          border-bottom-left-radius: 0;
        }

        &:nth-child(1){
          border-bottom-left-radius: 15px;
        }
      }
    }
  }

  .ability-row {
    flex-wrap: unset;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 1rem;

    .col-sm-3 {
      background-color: $color-white;
      &.col-without-padding {
        padding: 0px;
      }

      &:nth-child(1){
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }

  .counter {
    font-size: 1.2rem;
    font-weight: 600;
    white-space: pre;
  }

  .add-icon {
    cursor: pointer;
  }

  .abilities-section {
    margin-bottom: 1rem;
    position: relative;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    > .row {
      overflow-x: auto;
    }

    & + .adder {
      position: absolute;
      top: 1rem;
      right: 0;
      width: 2.5rem;
      z-index: 100;
    }
  }

  .opportunity-equation {
    margin-top: .5rem;
    font-size: 1rem;

    img {
      width: 2.5rem;
    }

    .part {
      margin: 0 .3rem;

      span {
        margin-left: .3rem;
      }
    }
  }

  .arrow {
    border: solid rgb(237, 237, 237);
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 8px;
    background: white;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    left: 48.1%;
    bottom: -1.2rem;
  }
  .opportunities-section__wrapper {
    position: relative;
  }

  .opportunities-section {
    overflow: auto;
    position: relative;

    > .row {
      overflow-x: auto;
    }

    & + .adder {
      position: absolute;
      top: 1rem;
      right: 0;
      width: 2.5rem;
      z-index: 100;
    }

    .row {
      flex-wrap: unset;
      width: 100%;
      padding-top: 1.3rem;
      .col-sm-4 {
        max-width: 32%;
        &.col-without-padding {
          padding: 0px;
          max-width: 34.2%;
          flex: 0 0 34.2%;
          // &:last-of-type {
          //   .opportunity-section {
          //     margin-right: 0px;
          //   }
          // }
        }
      }
      &.row-without-margin {
        margin-left: 0px;
      }
    }
  }
}

// over 1920px
@media (min-width: $screen-size-1920-min) {
  .worksheet-page1 {
    .arrow {
      bottom: -1rem;
    }
  }
}

@import '../../variables';

.header {
  max-width: 100%;
  height: 5.2rem;
  line-height: 5.2rem;
  text-transform: uppercase;
  background-color: $color-white;
  font-weight: 700;
  box-shadow: 0px 6px 15px -17px black;
  position: relative;
  border-bottom: 1px solid #ffffff;
  z-index: 15;

  .comment-wrapper {
    cursor: pointer;
	  display: flex;
    padding: 0 1.5rem;
  }

  .comment-icon {
    position: relative;
		display: flex;
		flex-direction: column;
		align-self: center;

		svg {
			width: 16px;
			height: 16px;
		}
  }

  .comment-dot {
    position: absolute;
		right: 0;
		top: 0;
		width: 6px;
		height: 6px;
		background-color: $color-primary;
		border-radius: 100%;
  }

  .save-status {
    display: flex;
    padding: 0 1.5rem;

    &[data-saved="true"] {
      svg {
        fill: green;
      }
    }

    &[data-saved="false"] {
      svg {
        fill: gray;
      }
    }
  }

  .save-icon {
    width: 18px;
    display: flex;
  }

  .save-text {
    font-size: 1rem;
    margin-left: 7px;
    text-transform: none;
    padding-top: 1px;
    color: gray;
  }

  .dropdown {
    display: inline-block;

    &.show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: none;
    }

    &.show > .btn-secondary.dropdown-toggle {
      border-color: $color-primary;
      background-color: $color-white;
      color: $color-black;
    }

    .btn-secondary:focus {
      border-color: $color-primary;
      background-color: $color-primary;
    }

    .btn-secondary:hover {
      border-color: $color-primary;
      background-color: $color-white;
      color: $color-black;
    }
  }

  .dropdown-menu {
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 35px !important;
    min-width: 100% !important;
  }

  .btn-example {
    margin-right: 10px;
  }

  .btn-download {
    margin-left: 10px;
  }

  .project-title {
    margin: auto;
  }

  .export {
    display: inline-block;
  }

  .user-profile {
    display: flex;
    align-items: center;
  }

  .user-avatar {
    width: 20px;
    height: 20px;
    background-color: $color-primary;
    border-radius: 100%;
    margin-right: 8px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1;
      color: white;
      text-transform: uppercase;
      margin: auto;
    }
  }

  .user-email {
    font-weight: 600;
    font-size: 1.1rem;
  }

  //input {
  //  border: none !important;
  //  text-transform: uppercase;
  //  font-weight: 700;
  //  font-size: 0.875rem;
  //  color: $color-black;
  //  width: 220px;
  //  display: inline-block;
  //  height: auto !important;
  //  line-height: 1rem;
  //
  //  &:focus {
  //    border: none; outline: none;
  //  }
  //}

  .dropdown-arrow {
    width: 1.5rem !important;
    cursor: pointer;
    display: inline-block;
  }

  .select-dropdown {
    width: 100%;
  }

  .add-icon {
    color: $color-primary;
    font-size: 2rem;
    vertical-align: bottom;
    padding-right: 1rem;
  }

  .delete-icon {
    color: $color-primary;
    width: 2rem;
    padding-left: 1rem;
    filter: invert(53%) sepia(55%) saturate(719%) hue-rotate(151deg) brightness(97%) contrast(88%);
    vertical-align: middle;
  }

  .create-text {
    color: $color-primary;
  }

  li:hover {
    .add-icon, .create-text, .delete-icon {
      color: white;
      filter: none;
    }
  }
}

.ml32 {
  margin-left: 32px;
}

.mr32 {
  margin-right: 32px;
}

.pl-sm {
  padding-right: 1rem
}

.font{
  font-size: 1.1rem!important;
  background-color: none!important;
}

@import '../../variables.scss';

.worksheet-page2 {
  // margin-top: 1rem;

  .question-mark-icon {
    width: 3rem;
    display: inline-block;
    vertical-align: bottom;
    margin-right: .5rem;
    cursor: pointer;
  }

  .select-section {
    padding: .7rem 0 .7rem 0.7rem;
    background-color: $color-white;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    font-weight: 600;

    img {
      width: 3.2rem;
      margin-right: 1.3rem;
    }
  }

  .section-title {
    padding: 1rem 0 0;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    display: inline-block;
  }

  .evaluate-section {
    background-color: $color-white;
    transition: opacity 0.35 ease-in-out;

    &[data-transparent="true"] {
      opacity: 0.5;
    }

    .h-padding-bottom {
      padding-bottom: 2rem;
    }
  }

  .overall {
    > .arrow-component .align-mid {
      width: calc((100% - 2rem) / 4);
    }
  }

  .section-evaluate {
    > .arrow-component .align-mid {
      width: 25%;
    }
  }

  .arrow-component {
    position: relative;
    line-height: 1.3rem;
    z-index: 90;

    .arrow-section {
      width: 3.5rem;
      height: 1.7rem;
      border-top: 1px solid $color-arrow-borders;
      border-bottom: 1px solid $color-arrow-borders;
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;

      &.filled {
        background-color: $color-primary;
      }

      &:nth-child(2n+1) {
        border-left: 1px solid $color-arrow-borders;
      }

      &:nth-child(8) {
        // border-right: 0px solid white;
      }
    }

    .levels-text {
      font-size: .7rem;
      line-height: .7rem;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      user-select: none;
      text-transform: uppercase;
      font-weight: 700;
      pointer-events: none;

      .align-mid {
        text-align: center;
        display: inline-block;
      }

      .align-right {
        text-align: right;
      }
    }
  }

  .overall-section {
    background-color: $color-white;
    padding-left: 2rem;
    padding-right: 1rem;

    > .row {
      margin: 0;
    }

    .arrow-icon {
      width: 2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -1px;
      z-index: -1;
    }

    .overall {
      width: 100%;
      position: relative;
      display: inline-block;
      padding-bottom: 1rem;

      .arrow-section {
        width: calc((100% - 2rem) / 8);
        height: 2.1rem;
      }
    }

    .col-sm-6 {
      display: flex;
    }

    .overall-image {
      margin-left: 2rem;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-self: flex-end;

      img {
        width: 5rem;
      }
    }
  }

  .section-border {
    background-color: $color-white;
    border-left: 14px $color-secondary solid;
    border-right: 6px $color-secondary solid;
    border-bottom: 6px $color-secondary solid;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 15px;
  }

  .section-border-right {
    background-color: $color-white;
    border-left: 6px $color-secondary solid;
    border-right: 14px $color-secondary solid;
    border-bottom: 6px $color-secondary solid;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 30px;
  }
}

// over 1920px
@media (min-width: $screen-size-1920-min) {
  .worksheet-page2 {
    .arrow-component {
      .arrow-section {
        border-width: 2px;

        &:nth-child(5n+1) {
          border-width: 2px;
        }

        &:nth-child(20) {
          border-width: 2px;
        }
      }
    }
  }
}

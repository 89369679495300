@import '../../variables';

.footer {
  text-align: center;
  margin: 8rem 0 2rem;

  .link {
    margin-left: 2.5rem;
    font-weight: 700;
    color: inherit;

    &:first-child {
      margin-left: 0;
    }
  }
}

@import '../variables.scss';

.share-container {
  .share-only {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: none;
    animation-name: darken;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-delay: infinite;

    @keyframes darken {
      from {background-color: none}
      to {background-color: rgba(0,0,0,0.7)}
    }
  }

  .share-main-container {
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    width: 40rem;
    height: 29rem;
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    user-select: none;

    .this-project {
      border-bottom: 1px solid #EAEAEA;
      height: 7rem;

      .share-container-row {
        display: flex;
        flex-direction: row;

        .shared {
          position:relative;
          top: 0.8rem;
          left: 2.6rem;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
        }

        .share-cross {
          position: absolute;
          top: 1rem;
          left: 36.5rem;
          cursor: pointer;
        }
      }

      .project-named {
        position:relative;
        top: 0.8rem;
        left: 2.6rem;
        width:100%;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
      }
    }

    .invite-text-fixed {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 1.1rem;
      margin-top: 0.5rem;
      margin-left: 2.6rem;
      color: black;
      font-style: normal;
    }

    .share-sucessful {
      position: absolute;
      top: 11.26rem;
      left: 6.5rem;
      font-size: 1.1rem;
      font-weight: 600;
      color: #00AEEF;
    }

    .emailsh {
      font-family: Open Sans;
      font-weight: 400;
      font-size: 1rem;
      padding-left: 2.6rem;
      padding-top:  0.8rem;
      color: black;
      font-style: normal;
    }

    form {
      .email-field {
        width: 86.5%;
        height: 3.3rem;
        border: 1px solid #748093;
        box-sizing: border-box;
        margin-left: 2.6rem;
        font-size: 1.2rem;
        padding-left: 1.2rem;
        font-weight: 400;

        &:focus {
          outline: none;
          border: 1px solid #748093;
        }

        &.error-email {
          background-color: rgb(255, 236, 236);
        }

        &.success-email {
          background-color: rgb(219, 245, 255);
        }
      }

      .view {
        position: absolute;
      }

      .edit {
        position: absolute;
        left: 24rem;
      }

      .edit-right {
        position: absolute;
        margin-left: 16rem;
      }

      .checkboxes {
        height: 2rem;
        width: 2rem;
        box-sizing: border-box;
        top: 19rem;
        left: 2.6rem;
        cursor: pointer;
      }

      .check-text {
        line-height: 3.2rem;
        margin-left: 5.8rem;
        color: #748093;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
      }

      .check-text-edit {
        line-height: 3.2rem;
        left: 21.8rem;
        color: #748093;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
      }

      .bottom-buttons {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 2.5rem 1.5rem;
      }

      .share-btn-send {
        background-color: #00AEEF!important;
        border: none!important;
        color: white;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        padding: 0.35rem 1rem;
        line-height: 2.3;

        &:focus {
          border: none;
          outline: none;
        }

        &.invited {
          opacity: 0.6;
        }
      }

      .share-btn-cancel {
        background-color: white!important;
        border: 1px solid black;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 2.5rem;
        color: black;
        margin-left: auto;
        padding: 0.35rem 1rem;
        line-height: 2.3;

        &:focus {
          border: 1px solid black;
          outline: none;
        }
      }
    }
  }
}

input[type='radio']:after {
width: 2.3rem;
height: 2.3rem;
left: -0.1rem;
bottom: 0.1rem;
border-radius: none;
position: relative;
background-color: white;
content: '';
display: flex;
visibility: visible;
border: 1px solid #748093;
line-height: 2.2rem;
}
input[type='radio']:checked:after {
width: 2.3rem;
height: 2.3rem;
left: -0.1rem;
bottom: 0.1rem;
border-radius: none;
position: relative;
background-color: #00AEEF!important;
content: '✔';
padding-left: 0.5rem;
color: white;
display: flex;
outline: 1px white;
border: none;
line-height: 2.2rem;
}

// over 992px
@media (min-width: $screen-md-min) {
  .check-text, .check-text-edit {
    top: 18.5rem;
  }
}  

// over 1200px
@media (min-width: $screen-lg-min) {
  .check-text, .check-text-edit {
    top: 18.5rem;
  }
}

  // over 1440px
  @media (min-width: $screen-xl-min) {
    .check-text, .check-text-edit {
      top: 18.5rem;
    }
  }

  // over 1680px
  @media (min-width: $screen-size-1680-min) {
    .check-text, .check-text-edit {
      top: 18.5rem;
    }
  }

  // over 1920px
  @media (min-width: $screen-size-1920-min) {
    .share-btn-send, .share-btn-cancel {
      top: 5rem;
    }
  }

.video-page {
  .player-wrapper {
    position: relative;
  }

  .heading {
    margin: 7.6rem 1.2rem 0;
  }

  .react-player {
    margin-left: 1.2rem;
  }
}

@import '../../variables.scss';

.checkbox-wrapper {
  height: 3.5rem;
  min-width: 20rem;
  background: $color-background;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: stretch;
  margin: auto;

  .label {
    margin-left: 1.2rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
}

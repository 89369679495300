@import '../../../variables';

.opportunity-container-wrapper {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    background-color: $color-opportunity-container;
    position: relative;
    width: 100%;
    flex: unset;
    max-width: unset;
    height: 10vh;
    padding: 0;
    justify-content: center;

    .sticky-note {
      &__app, &__title {
        display: block;
      }

      &__app {
        margin-bottom: .5rem;
      }

      &__title {
        margin-top: 0 !important;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .opportunity-app-title {
      font-size: 0.9rem;
      line-height: 1;
      margin-top: .5rem;
    }

    .opportunity-title {
        line-height: 1rem;
        font-size: .9rem;
        display: inline-block;
        outline: none;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        background-color: $color-opportunity-container;
        text-align: center;
        resize: none;
        margin-top: .5rem;
        margin-left: 8px;
        margin-right: 8px;
        padding: 0;

        &:focus::placeholder {
          color: transparent;
        }

        &:focus {
          margin-top: .5rem;
        }

        &.empty {
          margin-top: 0;
          @media screen and (max-height: 770px){
            font-size: 0.8rem;
          }
          @media screen and (max-height: 630px){
            font-size: 0.75rem;
          }

          &:focus {
            margin-top: .5rem;
          }
        }
    }

    .delete-opportunity-button{
        display: none;
        position: absolute;
        flex-direction: column;
        top: -1vh;
        right: -1vh;
        width: 1.8rem;
        justify-content: center;
        cursor: pointer;
        line-height: 15px;
    }

    &:hover .delete-opportunity-button{
        display: block;
    }
}

// over 1920px
@media (min-width: $screen-size-1920-min) {
  .opportunity-container {
    .delete-opportunity-button {
      line-height: 14px;
    }
  }
}

@import 'variables';
@import 'fonts';

* {
  box-sizing: border-box;
}

html {
  tap-highlight-color: $color-transparent;
  text-size-adjust: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 8px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  background-color: $color-background;
  position: relative;
}

////links
a {
  transition: .3s;

  &,
  &:active,
  &:hover,
  &:link,
  &:visited {
    color: $color-black;
    text-decoration: none;
  }

  &[name] {
    position: relative;
    top: -14rem;
  }

  &:focus {
    outline: none;
  }
}

//svg
.svg {
  path,
  rect {
    stroke-width: 0;
  }
}

.page,
.page-container {
  min-height: 100vh;
  overflow: hidden;
}

.page {
  position: relative;
  color: $color-black;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
}

#root {
  position: relative;
  overflow: hidden;
}

.page-content {

  &.no-nav {
    margin-left: 0;
  }
}

@keyframes hide {
  0% {
    visibility: visible;
  }
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.projects-loading {
  z-index: 1000000000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .85);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out;

  &[data-active="true"] {
    opacity: 1;
  }

  &[data-active="false"] {
    opacity: 0;
    animation-name: hide;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  &__title {
    font-weight: 600;
    width: 100%;
    text-align: center;
  }
}

.overview-container {
  width: 100%;
}

.visually-hidden {
  display: none;
}

.header-wrapper {
  background-color: $color-white;
  width: 100%;
  position: fixed;
  z-index: 100;
}

.align-right {
  text-align: right;
}

.box-tooltip {
  .tooltip {
    z-index: 1000;

    .tooltip-inner {
      max-width: 25rem;
      overflow: auto;
      max-height: 13rem;
    }
  }
}

.attractiveness-tooltip {
  .tooltip {
    .tooltip-inner {
      max-width: 32rem;
    }
  }
}

.agile-focus-tooltip {
  .tooltip {
    .tooltip-inner {
      max-width: 53rem;
    }
  }
}

.tooltip {
  z-index: 1000;

  &:hover {
    .tooltip-container {
      .close-tooltip-icon {
        display: block;
      }
    }
  }

  .tooltip-inner {
    background-color: $color-secondary;
    color: $color-black;
    border: 1px solid black;
    font-size: 1.2rem;
    line-height: 1.5rem;
    max-width: 43rem;
    text-align: left;
    white-space:pre-wrap;

    .tooltip-link {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  &.show {
    opacity: 1.0;
  }

  .tooltip-container {
    .close-tooltip-icon {
      display: none;
      position: absolute;
      flex-direction: column;
      top: -1vh;
      right: -1vh;
      width: 1.8rem;
      justify-content: center;
      cursor: pointer;
      line-height: 15px;
    }

    .box {
      width: 5.5rem;
      height: 5rem;
      background-color: $color-opportunity-container;
      position: relative;
      margin-bottom: .5rem;

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
        line-height: 0.9rem;
        font-size: .9rem;
        // overflow: hidden;
        // overflow-y: auto;
        // overflow-wrap: break-word;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

// .bs-tooltip-auto[x-placement^="right"] .arrow::before {
//   border-right-color: $color-black;
// }

.saved,.saving {
  color: grey;
  font-size: 1.4rem;
  display: inline-block;
  padding-left: 1rem;
  z-index: 10;
  text-transform: none;
}

.btn {
  background-color: $color-primary;
  color: $color-white;
  font-size: 1.4rem;
  border-radius: 0;
  padding: .7rem 2rem;
  border: .2rem $color-primary solid;
  vertical-align: unset;

  &.transparent {
    background-color: transparent;
    color: $color-black;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }

    &:visited {
      color: $color-black;

      &:hover {
        color: $color-white;
      }
    }
  }

  &:hover {
    background-color: transparent;
    color: $color-black;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  &.save-button {
    position: absolute;
    top: 0;
    right: -22rem;
    border: .2rem #28a745 solid;
    cursor: pointer;
    z-index: 10;
    color: white;

    &.saved {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
        border: .2rem #218838 solid;
      }
    }

    &.unsaved {
      background-color: #bd2130;
      border: .2rem #bd2130 solid;

      &:hover {
        background-color: #c82333;
        border: .2rem #c82333 solid;
      }
    }

    &.saving {
      background-color: #117a8b;
      border: .2rem #117a8b solid;

      &:hover {
        background-color: #138496;
        border: .2rem #138496 solid;
      }
    }
  }

  &:visited {
    color: $color-white;

    &:hover {
      color: $color-black;
    }
  }

  &.download, &.back {
    margin: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

.left-navigation {
  display: flex;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: center;
  background-color: white!important;
}

// over 768px
@media (min-width: $screen-sm-min) {
  a {
    &[name] {
      position: relative;
      top: -11rem;
    }
  }
}

// over 992px
@media (min-width: $screen-md-min) {
  body,
  html {
    font-size: 8px;
  }
}

// over 1200px
@media (min-width: $screen-lg-min) {
  body,
  html {
    font-size: 9px;
  }
}

// over 1440px
@media (min-width: $screen-xl-min) {
    body,
    html {
      font-size: 10px;
  }
}

// over 1680px
@media (min-width: $screen-size-1680-min) {
  body,
  html {
    font-size: 11px;
  }
}

// over 1920px
@media (min-width: $screen-size-1920-min) {
  body,
  html {
    font-size: 13px;
  }
}



.new-btn {
  color: black;
  text-transform: none!important;
  background-color: none!important;
  font-style: normal;
  font-family: Open Sans;
  color: black!important;
  font-size: 1.1rem;
  font-weight: normal!important;
  border: none;
  vertical-align: unset;
  user-select: none;
  &:hover{ color: rgb(110, 110, 110)!important; cursor: pointer;}
  &.exportbtn {text-align: center;}
  &.export {text-align: center;}
  & span {text-align: center;}

}



.shareBtn {
  align-self: center;
  position: relative;
  font-size: 1.2rem;
  line-height: 0.001rem;
  height: 2.8rem;
  background-color: #00AEEF;
  border: 1px solid #00AEEF;
  color: white!important;
  text-transform: none!important;
  font-weight: 400;
  font-style: normal;
  font-family: Open Sans;
  user-select: none;
  outline: none;
  cursor: pointer;
  padding: 1px 10px;

  &:focus {
    outline: none;
  }

  &.save-button {
    position: absolute;
    top: 1rem;
    right: 7.3rem;
    border: none;
    cursor: pointer;
    z-index: 10;

    &.saved {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
      }
    }

    &.unsaved {
      background-color: #bd2130;

      &:hover {
        background-color: #c82333;
      }
    }

    &.saving {
      background-color: #117a8b;

      &:hover {
        background-color: #138496;
      }
    }
  }
}

.line {
  align-self: center;
  min-width: 0.1rem;
  min-height: 3.9rem;
  background-color: #EAEAEA;
}

.darken {
  background-color: rgba(0,0,0,0.8); /*dim the background*/
}

.header-image {
  height: 2rem;
  width: 2rem;
  margin-left:2.2rem;
  margin-right:2.2rem;
  z-index: 1;
  user-select: none;
  -moz-user-select: none;
  align-self: center;
  cursor:pointer;
}

.header-circle {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 100%;
  background-color: #748093;
  font-size: 1rem;
  align-self: center;
  user-select: none;
  cursor: pointer;
  display: flex;

  &__number {
    width: 100%;
    text-align: center;
    font-weight: 400;
    color: white;
    line-height: 2.4;
  }
}

.mgr2 {
  margin-right: 2.2rem
}

//Editable layout content

.view-page {
  margin: 0.7rem 5.55rem 0!important;
}

.opportunity-set-content {
  margin-left: 0.75rem!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.attractiveness-map-content {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.agile-focus-content, .worksheet-page {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.abilities-wrapper, .opportunities-section {
  max-width: calc(100% - 40px);
}

.add-icon {
  margin-right: 3rem!important;
}

.worksheet-page2 {
  background-color: none!important;
}

.width-fix {
  max-width: 90vw!important;
}

// header dropdown only

.header-dropdown-only{
  display: flex!important;
  padding-left: 1rem!important;
  & ul {
    margin-left: -1rem!important;
    width: 100%;
  }
}

.sub-segment-manage {
  margin-right: 10rem;
}


// svg anim

svg:hover {
  cursor: pointer;
  .on-mouse-over-svg {
    stroke: #00aeef;
  }
}

.on-mouse-over-fill:hover {
  fill: #00aeef;
}

.empty-box {
  background-color: none!important;
  height: 100%;
  width: 2.4rem;
}

.account-icon {
  position: relative;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  text-align: center;
  background-color: #00AEEF;
  color: white;
}

@import '../../variables.scss';

.attractiveness-section {
  margin: 2.5rem 3rem;
  border-bottom: 2px $color-secondary solid;
  height: 13rem;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  .section-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 2.2rem;

    img {
      width: 3.3rem;
    }
  }

  .section-evaluate {
    display: inline-block;

    .arrow-icon {
      width: 1.5rem;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0px;
      z-index: -1;
    }

    .section-heading {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.2rem;
      margin-top: 1.5rem;
      padding-bottom: 0.5rem;
    }

    .info-text {
      font-size: .9rem;
      line-height: 1.2rem;
      font-weight: 600;
      
    }
  }

  .section-comment {
    position: absolute;
    top: 0;
    right: 0;
  }
}

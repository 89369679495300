@import '../../variables';

.view-page {
  margin: 9.4rem 2.6rem 0;

  .heading {
    background-color: $color-background;

    margin-top: 4rem;

      .title {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;

        .notes-btn {
          float: right;
          height: 24px;
          cursor: pointer;
          margin-right: 2rem;
          margin-top: 4px;
        }
      }

      .sheet-icon {
          width: 2.2rem;
          align-self: center;
          display: inline-block;

          &.smaller {
            width: 3rem;
          }
      }

      .description {
        text-transform: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
        max-width: 30rem;
        margin-left: 1.5rem;
        line-height: 1.5em;
        align-self: center;

        .link {
          text-decoration: underline;
        }
      }
  }

  .footer {
    margin-left: 2rem;

    .box-icon {
      width: 3rem;
      align-self: center;
    }

    .hint {
        font-size: 1.1rem;
        margin-left: 1.5rem;
        color: $color-primary;
    }
  }
}

.gpt-feature-page {
  max-width: 800px;
  margin: 9.4rem auto 0;

  & a {
    text-decoration: underline;
    color: #1baae3;
  }

  .build-version {
    font-size: 0.7em;
    font-weight: bold;
  }
}

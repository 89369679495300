@import '../variables.scss';

.project-members {
  .members-only {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background-color: none;

    animation-name: darken;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-delay: infinite;

    @keyframes darken {
      from {background-color: none}
      to {background-color: rgba(0,0,0,0.7)}
    }
  }

  .members-main-container {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    width: 44rem;
    height: 42rem;
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    user-select: none;
    z-index: 101;

    .members-container-column {
      display: flex;
      flex-direction: column;

      .this-project-members {
        height: 8rem;
        font-family: Open Sans;
        color: black;
        font-style:normal;

        &.project-name-members {
          font-weight: 400;
          height: 3rem;
          font-size: 	1.2rem;
          border-bottom: 1px solid #EAEAEA;
          line-height: 2rem;
          padding-left: 2rem;
          color: black;
        }
      }

      &.members-map {
        overflow-y: scroll;
      }

      &.btn-panel-height-mbr {
        height: 10rem;
        justify-content: center;
        margin-left: 3.2rem;
        margin-right: 3.2rem;
        margin-top: .8rem;
      }

      .members-container-row {
        display: flex;
        flex-direction: row;

        .this-project-members {
          height: 8rem;
          font-family: Open Sans;
          color: black;
          font-style:normal;

          &.share-this-project-members {
            font-weight: 600;
            font-size: 1.4rem;
            padding-top: 1rem;
            padding-left: 2rem;
            height: 4rem;
          }

        }

        .members-cross {
          left: 41.5rem;
          position: absolute;
          cursor: pointer;
        }
      }

      .btn-group {
        justify-content: space-between;

        .members-btn-send {
          position:relative;
          left: -1rem;
          bottom: 2rem;
          height: 3.5rem;
          width: 10rem;
          background-color: #00AEEF!important;
          border: none;
          color: white;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 2.5rem;

          &:focus {
            border: none;
            outline: none;
          }
        }

        .members-btn-cancel {
          position:relative;
          left: 1rem;
          bottom: 2rem;
          height: 3.5rem;
          width: 10rem;
          background-color: white!important;
          border: 1px solid black;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 2.5rem;
          color: black;

          &:focus {
            border: 1px solid black;
            outline: none;
          }
        }
      }
    }

    .fixed-position {
      position: relative;
      height: 7rem;
      border-top: 1px solid #EAEAEA;
    }
  }
}

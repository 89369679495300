@import '../../../variables';

.attractiveness-map-content {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;

  .sticky-note {
    &__app, &__title {
      display: block;
    }

    &__app {
      margin-bottom: .5rem;
    }

    &__title {
      margin-top: 0 !important;
    }
  }

  .previous-screen-element {
    width: 55vh;
    margin-left: -39vh;
    opacity: 0.25;
    @media screen and (min-height: 800px) {
      width: 60vh;
      margin-top: -1vh;
      margin-left: -45vh;
    }
  }


  .next-screen-element {
    width: 55vh;
    padding-top: 7px;
    margin-top: 6vh;
    margin-left: 10rem;
    opacity: 0.25;
    @media screen and (min-height: 800px) {
      margin-right: -6vh;
      margin-top: 6.2vh;
      width: 61vh;
    }

  }

  .attractiveness-map-element {
    position: relative;
    margin-left: 10rem;
    width: 59.3vh;
    padding-top: 4px;
    margin-top: 4vh;
    @media screen and (min-height: 800px) {
      width: 65vh;
    }
  }

  .box {

    --box-width: calc(10.4vh - 2px);
    --box-height: calc(10.1vh - 2px);
    --box-gap: 1.2vh;
    --base-left: 6vh;
    --base-bottom: 8.2vh;

    @media screen and (min-height: 800px) {
      --box-width: calc(11vh - 2px);
      --box-height: calc(10.7vh - 2px);
      --box-gap: 2.1vh;
      --base-left: 6.5vh;
      --base-bottom: 8.8vh;
    }

    width: var(--box-width);
    height: var(--box-height);
    background-color: $color-opportunity-container;
    position: absolute;
    bottom: var(--base-bottom);
    left: var(--base-left);
    z-index: 10;
    cursor: pointer;
    border: 2px solid $color-white;

    &.highlighted {
      z-index: 50 !important;
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 0px 3px;
      text-align: center;
      max-height: 90%;
      transform: translateX(-50%) translateY(-50%);
      line-height: 1rem;
      font-size: .9rem;
      // overflow: hidden;
      // overflow-y: auto;
      // overflow-wrap: break-word;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .count {
      width: 1.3rem;
      height: 1.3rem;
      font-size: .9rem;
      line-height: 1.3rem;
      background-color: $color-primary;
      position: absolute;
      top: -5px;
      right: -10px;
      text-align: center;
      color: white;
      border-radius: 50px;
    }

    &.xlowmid {
      margin-left: calc( (var(--box-width) + var(--box-gap)) / 2);
    }

    &.xmid {
      margin-left: calc( var(--box-width) + var(--box-gap) );
    }

    &.xmidhigh {
      margin-left: calc( var(--box-width) + var(--box-gap) + (var(--box-width) + var(--box-gap)) / 2 );
    }

    &.xhigh {
      margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + var(--box-gap) * 2);
      @media screen and (min-height: 800px) {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + var(--box-gap));
      }
    }

    &.xhighsuper {
      margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + (var(--box-width) + var(--box-gap)) / 2 + var(--box-gap) * 2);
      @media screen and (min-height: 800px) {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 2 + (var(--box-width) + var(--box-gap)) / 2 + var(--box-gap));
      }
    }

    &.xsuper {
      margin-left: calc( (var(--box-width) + var(--box-gap)) * 3 + var(--box-gap) * 2);
      @media screen and (min-height: 800px) {
        margin-left: calc( (var(--box-width) + var(--box-gap)) * 3 + var(--box-gap));
      }
    }

    &.ylowmid {
      margin-bottom: calc( (var(--box-height) + var(--box-gap)) / 2);
    }

    &.ymid {
      margin-bottom: calc( var(--box-height) + var(--box-gap) );
    }

    &.ymidhigh {
      margin-bottom: calc( var(--box-height) + var(--box-gap) + (var(--box-height) + var(--box-gap)) / 2 );
    }

    &.yhigh {
      margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 2 );
    }

    &.yhighsuper {
      margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 2 + (var(--box-height) + var(--box-gap)) / 2 );
    }

    &.ysuper {
      margin-bottom: calc( (var(--box-height) + var(--box-gap)) * 3 );
    }

    &.second {
      bottom: calc(var(--base-bottom) - 6px);
      left: calc(var(--base-left) + 6px);
      border-left: 1px solid white;//$color-light-grey;
      border-top: 1px solid white;//$color-light-grey;
      z-index: 100;
    }

    &.third {
      bottom: calc(var(--base-bottom) - 12px);
      left: calc(var(--base-left) + 12px);
      border-left: 1px solid white;//$color-light-grey;
      border-top: 1px solid white;//$color-light-grey;
      z-index: 1000;
    }
  }
}

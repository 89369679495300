@import '../../variables';

.overview-page {
  margin: 10rem 24px 0;

  .heading {
    text-transform: uppercase;
    margin-top: 4rem;
    font-size: 2.5rem;
    font-weight: 600;
    margin-left: 3rem;
  }

  .notes-btn {
    float: right;
    height: 24px;
    cursor: pointer;
    margin-right: 2rem;
    margin-top: 4px;
  }

  > .overview-container {
    width: 100%;
    display: grid;
    grid-template-columns: 0.93fr 1fr 0.93fr;
    gap: 24px;
  }
}

.height-optimize {
  height: 70vh;
}
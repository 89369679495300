@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('../assets/fonts/open-sans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../assets/fonts/open-sans-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/open-sans-v14-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../assets/fonts/open-sans-v14-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-v14-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-v14-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-v14-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-v14-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/open-sans-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../assets/fonts/open-sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/open-sans-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../assets/fonts/open-sans-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/open-sans-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/open-sans-700italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/open-sans-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/open-sans-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

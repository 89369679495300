@import "../../variables.scss";

$bem: "comment-list";

.#{$bem} {
	&[hidden] {
		display: none;
	}

	&__close {
		cursor: pointer;
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		animation-name: darken;
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-delay: 0s;

		@keyframes darken {
			from {
				background-color: none;
			}
			to {
				background-color: rgba(0, 0, 0, 0.7);
			}
		}
	}

	&__container {
		min-width: 36.1rem;
		height: 100%;
		position: fixed;
		top: 0px;
		right: 0px;
		transform: translateX(0%);
		transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
		background-color: white;
		z-index: 1010;
		overflow-y: auto;
	}

	&__content {
		padding: 4.8rem 3.2rem;
		min-height: 100%;
	}

	&__head {
		position: relative;
		padding-bottom: 12px;
		border-bottom: 1px solid #eaeaea;
	}

	&__cross {
		cursor: pointer;
		position: absolute;
		top: 3.5px;
		right: 0;
		width: 20px;
		height: 20px;
		display: flex;
	}

	&__top {
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 2.5rem;
	}

	&__bubble {
		padding-right: 1.1rem;
		display: flex;

		svg {
			width: 18px;
			height: 18px;
		}
	}

	&__title {
		color: black;
		font-weight: 600;
		user-select: none;
		font-size: 1.8rem;
		font-family: Open Sans;
	}

	&__count {
		font-weight: 400;
	}

	&__bottom {
		color: $color-primary;
		font-weight: 600;
		font-size: 1.4rem;
	}

	&__list {
		display: flex;
		flex-direction: column;
	}

	&__input {
		position: sticky;
		bottom: 0;
		width: 100%;
		height: 175px;
		padding: 15px 30px 0;
		background-color: #EAEAEA;
	}

	&__textarea {
		resize: none;
		display: block;
		width: 100%;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: #574f4f;
		outline: none;
		padding: 8px;
		border: 1px solid #CED1D7;
		text-align: left;
		height: 90px;
		margin-bottom: 8px;
	}

	&__send {
		background-color: #00AEEF;
		border: none;
		height: 2.7rem;
		width: 14.4rem;
		font-size: 1.2rem;
		line-height: 2.7rem;
		font-family: Open Sans;
		font-weight: 600;
		color: white;
		font-style: normal;
	}
}

@import '../../variables.scss';

.selection-boxes {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 1rem;
  width: 100%;

  .box {
    background: $color-background;
    min-height: 3.5rem;
    width: 73%;
    padding: 0.5rem 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:first-child {
      margin-left: auto;
    }

    &:nth-child(2) {
      margin: auto;
    }
  }

  .active {
    background: $color-primary;
    color: $color-white;
  }
}

@import '../../variables.scss';

.help-page {
  position: relative;
  margin: 9.4rem 2.6rem 0;

  .heading {
    background-color: $color-background;
    .section-heading {
      margin-bottom: 1rem;
      font-weight: 700;
      font-size: 3rem;
    }

    .title {
      text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: 600;
      display: block;
      // margin-bottom: 1.5rem;
      margin-bottom: 3.5rem;
      
    }
    .description {
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      max-width: 30rem;
      margin-left: 1.5rem;
      line-height: 1.5em;
      align-self: center;

      .link {
        text-decoration: underline;
      }
    }
  }
  .overview-wrapper {
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 1;
  }
  .overview-block {
    margin: 3rem 0rem;
  }
  .overview-heading {
    margin-bottom: 1rem;
    h3 {
      font-weight: 700;
    }
  }
  .overview-text {
    margin: 0.6rem 0rem;
    a {
      font-weight: 700;
    }
  }
  .overview-list {
    margin: 2rem 0rem;
    position: relative;
    a {
      font-weight: 700;
    }
    &.no-bullet {
      list-style: decimal;
    }
  }
  .h-column {
    width: 100%;
    max-width: 800px;
  }
  .row {
    margin: 0px auto;
    &.faq-row {
      margin-top: 3rem;
      margin-bottom: 10rem;
      align-content: center;
      justify-content: center;
      & > div {
        width: 100%;
        max-width: 800px;
        font-size: 1.3rem;
        line-height: 2rem;
        letter-spacing: 1;
        & > section {
          border: none;
        }
        section {
          section {
            padding: 2rem 0;
          }
        }
        div[role="button"] {
          color: #574f4f;
          font-weight: 700;
        }
        div[role="region"] {
          a {
            font-weight: 700;
          }
        }
        h2 {
          font-weight: 700 !important;
          font-size: 3rem;
        }
      }
    }
  }
}

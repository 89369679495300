@import '../variables.scss';

.delete-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .dialog-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: black;
    opacity: 0.7;
  }

  .dialog-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 350px;
    white-space: pre-wrap;
    line-height: 1.4;
    text-transform: none;

    .dialog-title {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 20px;
    }

    .dialog-options {
      display: flex;
      flex-direction: row;

      .dialog-button {
        cursor: pointer;
        display: inline-block;
        padding: 10px 15px;
        font-size: 1.2rem;
        font-weight: 400;
        border: 1px solid #00AEEF;
        margin-right: 10px;

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &.yes {
          background-color: #00AEEF;
          color: white;
        }

        &.no {
          color: #00AEEF;
        }
      }
    }
  }
}

.project-dropdown {
  --background: hsl(0deg 0% 95%);
  --padding: 4px;
  --dropdown-h: 30px;
  --button-w: calc(var(--dropdown-h) - (2 * var(--padding)));

  position: relative;
  height: var(--dropdown-h);
  align-self: center;

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }

  .input-wrapper, .project {
    &:hover {
      .controls {
        opacity: 1;
      }
    }
  }

  .input-wrapper {
    cursor: pointer;
    width: 275px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--background);
    padding: var(--padding) calc(2 * var(--padding)) var(--padding) calc(3 * var(--padding));
    margin-right: var(--padding);
  }

  .input-arrow {
    color: $color-primary;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 2px;
  }

  .input-current {
    padding-left: calc(2 * var(--padding));
  }

  .input {
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: bold;
    text-transform: none;
  }

  .button-wrapper {
    height: 100%;

    &[data-fixed="true"] {
      width: auto;
    }

    &[data-fixed="false"] {
      width: var(--dropdown-h);
    }

    &[data-success="true"] {
      .button {
        background-color: $color-turqoise;
      }
    }
  }

  .button-add {
    line-height: 1;
    font-size: 2rem;
    background-color: $color-primary;
  }

  .button-apply {
    line-height: 2;
    font-size: 1.25rem;
    background-color: $color-primary;
  }

  .button {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    display: block;
    color: white;
    transition: background-color 0.35s ease-in-out;
  }

  .icon {
    position: absolute;
    line-height: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;

    &-success {
      font-size: 1.25rem;
    }
  }

  .label {
    padding: 0 1rem;
  }

  .projects {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: calc(-1 * var(--padding));
    transform: translateY(100%);
    background-color: var(--background);
    padding: calc(3 * var(--padding));
    text-transform: none;
    line-height: 1.2;
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(2 * var(--padding));

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .role {
    font-size: 1rem;
    font-weight: bold;
    opacity: 0.5;
  }

  .project {
    display: flex;
    cursor: pointer;
    padding: calc(2 * var(--padding)) 0;
    border-top: 1px solid #e0e0e0;
    margin-top: calc(2 * var(--padding));

    &[data-active="true"] {
      .title {
        color: $color-primary;
      }
    }
  }

  .title {
    width: 100%;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: bold;

    &:hover {
      color: $color-primary;
    }
  }

  .controls {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .control {
    margin-right: calc(1.5 * var(--padding));
    height: 14px;

    svg {
      vertical-align: top;
    }

    &:hover {
      color: $color-primary;
    }

    &-edit {
      width: 12px;
    }

    &-delete {
      width: 14px;
      margin-right: 0;
    }
  }
}
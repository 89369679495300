@import '../../variables.scss';

$section-border: 0.3rem solid $color-background;
$inner-border: 0.14rem solid $color-background;
$border-lg: 1.6rem solid $color-background;
$radius: 1.5rem;

.worksheet-page3 {
  margin: 1.5rem 0;
  padding-left: 1.5rem;

  .select-section {
    transition: opacity 0.35 ease-in-out;
    padding-left: 1.5rem;
    margin-left: 0;
    margin-right: 0;

    &[data-transparent="true"] {
      opacity: 0.5;
    }
  }

  .second-row {
    font-size: 1.4rem;
  }

  .counter {
    font-size: 1.2rem;
    font-weight: 600;
    white-space: pre;
  }

  .opportunities-section {
    max-width: calc(100% - 10px);

    & + .adder {
      cursor: pointer;
      position: absolute;
      top: 1.25rem;
      right: 0px;
      width: 2.5rem;
      z-index: 100;
    }
  }

  //.opportunities-section + .add-icon {
  //  position: absolute;
  //  transform: translateY(0%);
  //  top: 4px;
  //  right: -10px;
  //  cursor: pointer;
  //  width: 2.5rem;
  //  z-index: 100;
  //}

  .question-mark-icon {
    position: absolute;
    left: -3.4rem;
    top: -0.5rem;
    width: 3rem !important;
    margin-right: .5rem;
    cursor: pointer;
  }
  .selection-container {
    padding: 0px 10px;
  }

  .section-text {
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 600;
    .section-text-title {
      font-size: 1.1rem;
      line-height: 2rem;
    }
    .section-text-intro {
      // padding-top: 1rem;
      position: relative;
      top: 0.7rem;
    }

    .number {
      padding-right: 0;
      padding-left: 0px;
      font-size: 1rem;
      line-height: 1.3rem;
      align-self: flex-start;
      max-width: 3.3333%;
      &.with-line-height {
        line-height: 2rem;
        margin-right: 0.5rem;
      }
    }

    .text {
      padding-left: 2px;
      padding-right: 0;
      line-height: 1.4rem;

      ul {
        padding-left: 15px;
        line-height: 1.4rem;
      }
    }

    .section-heading {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  .select-opportunity-section {
    position: relative;
    max-width: 33.4%;

    .add-icon {
      position: absolute;
      transform: translateY(0%);
      top: 4px;
      right: -10px;
      cursor: pointer;
      width: 2.5rem;
      z-index: 100;
    }

    &:hover {
      .section-row {
        .delete-icon {
          visibility: visible;
        }
      }
    }
  }

  .section-row {
    min-height: 8.8rem;
    &.bt-lg {
      min-height: calc(8.8rem + 1.6rem);
    }

    // img{
    //   width: 2.5rem;
    // }


    .delete-icon {
      width: 2.5rem;
      visibility: hidden;
      position: absolute;
      top: -1.3rem;
      left: -0.3rem;
      cursor: pointer;
      transform: rotate(45deg);
    }
  }

  .section-row-sm {
    min-height: 6rem;
    padding-left: 2.9rem;

    & > .selection-container {
      max-width: calc(75% - 60px);
    }
  }

  .white-bg {
    background: $color-white;
  }

  .inner {
    padding: 0 1rem;
  }

  // borders
  .bt-lg {
    border-top: $border-lg;
  }

  .bb-md {
    border-bottom: $section-border;
  }

  .br-md {
    border-right: $section-border;
  }

  .bb-sm {
    border-bottom: $inner-border;
  }

  // border radius

  .radius-bl {
    border-bottom-left-radius: $radius;
  }

  .radius-br {
    border-bottom-right-radius: $radius;
  }

  //align
  .v-align {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .h-align {
    display: flex;
    justify-content: center;
  }

  .arrow {
    border: solid rgb(237, 237, 237);
    border-width: 0 9px 9px 0;
    display: inline-block;
    padding: 8px;
    background: white;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    left: 45%;
    top: -2.1rem;
  }

  .box-icon {
    width: 3.5rem;
  }

  .opportunities-section {
    flex-wrap: unset;
    overflow: auto;
  }
}

@import '../../variables.scss';

.circle-image {
  .circle {
    height: 1.9rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid $color-black;
  }

  .circle-active {
    height: 1.9rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid $color-white;
  }

  .circle-image-1 {
    display: flex;
  }

  .circle-image-2 {
    display: flex;
    margin: 0 0.7rem;
    #circle2 {
      margin-left: -.8rem;
    }
  }

  .circle-image-3 {
    display: flex;
    margin: 0 0.9rem;
    #circle2 {
      margin-left: -1.3rem;
    }
  }
}

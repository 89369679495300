
.comment {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  position: relative;

  > .comment {
    .comment-column {
      .comment-data {
        .delete {
          right: 0;
        }
      }
    }
  }

  .comment-column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 3.3rem;
    position: relative;

    &.text-field {
      height: 100%;
    }

    .comment-data {
      display: flex;
      flex-direction: row;
      align-items: center;

      .comments-name-item {
        line-height: 1.9rem;
        margin-left: 1.2rem;
        font-weight: 600;
        font-size: 1.4rem;
        min-height: 100%;
        color: black;
        margin-right: 2rem;
      }

      .delete {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        right: 25px;
      }

      .reply {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        right: 0;

        .reply-svg {
          &:hover {
            cursor: pointer;
            fill: #00aeef;
          }
        }
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;

    &.text-field {
      height: 100%;
    }

    .comments-text-item {
      text-align: start;
      margin-top: .8rem;
      padding-left: 4.1rem;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      word-break: break-all;
      color: black;
      line-height: 1.9rem;
    }
  }

  .reply-container {
    display: flex;
    flex-direction: column;

    .comment-column {
      margin-top: 1.2rem;
    }

    .reply-message {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      height: 12.6rem;
      width: 100%;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      margin-top: .4rem;


      .reply-textarea {
        outline: none!important;
        border: 2px solid #EAEAEA;
        margin-left: 4rem;
        margin-bottom: .8rem;
        padding: .8rem;
        line-height: 1.4rem;
        height: 100%;
      }

      .styled-button {
        background-color: #00AEEF;
        border: none;
        height: 2.7rem;
        width: 14.4rem;
        font-size: 1.2rem;
        line-height: 2.7rem;
        font-family: Open Sans;
        font-weight: 600;
        color: white;
        font-style: normal;
        margin-left: 4rem;

        &:focus {
         border: none; outline: none;
        }

        &:hover {
         background-color: #0094ca;
        }
      }
    }
  }
}

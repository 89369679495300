@import '../variables.scss';

.comments-container {
  .msg-only {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: none;
    z-index: 100;
    animation-name: darken;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-delay: infinite;

    @keyframes darken {
      from {
        background-color: none
      }
      to {
        background-color: rgba(0,0,0,0.7)
      }
    }
  }

  .msg-container {
    min-width: 36.1rem;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    transform: translateX(0%);
    transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
    background-color: white;
    z-index: 1010;

    &.hidden-msg {
      transform: translateX(100%);
    }

    .cross {
      position: absolute;
      top: 3.7rem;
      right: 3.7rem;
      line-height: 1rem;
      cursor: pointer;
    }

    .notes-content-comment {
      padding: 0 3.2rem;
      overflow: auto;
      height: 68vh;
    }

    .notes-flex-base-container {
      display: flex;
      margin: auto;
      flex-direction: row;
      align-items: center;
      padding: 4.8rem 3.2rem 0;
      line-height: 2.5rem;

      .notes-flex-item {
        display: flex;
        flex-direction: row;

        &.note {
          padding-right: 1.1rem;
        }

        &.textmsg {
          color: black;
          font-weight: 600;
          user-select: none;
          font-size: 1.8rem;
          font-family: Open Sans;
        }
      }
    }

    .rect-container {
      display:flex;
      justify-content: center;
      margin: 1.2rem 3.2rem;

      &.in-reply, &.in-list {
        margin: 1.2rem 0;
      }

      .rect {
        width: 100%;
        height: 1px;
        background-color: #EAEAEA;
      }
    }

    .notes-content-textarea {
      .notes-content {
        position: absolute;
        top: 80%;
        width: 100%;
        height: 18.7rem;
        padding-top: 1.3rem;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        background-color: #EAEAEA;

        textarea {
          resize: none;
          display: block;
          padding: 0;
          width: 100%;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: $color-black;
          outline: none;
          padding: .8rem;
          border: 1px solid #CED1D7;
          text-align: left;
          height: 9.1rem;

          &::placeholder {
            color: black;
            opacity: 1;
          }
        }

        .styled-button {
          background-color: #00AEEF;
          border: none;
          height: 2.7rem;
          width: 14.4rem;
          font-size: 1.2rem;
          line-height: 2.7rem;
          font-family: Open Sans;
          font-weight: 600;
          color: white;
          font-style: normal;

          &:focus {
           border: none; outline: none;
          }

          &:hover {
           background-color: #0094ca;
          }
        }
      }
    }
  }
}
